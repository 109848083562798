/*----- Home page brands slider ----- */
.brands-slider {
  padding:      15px 0;
  border-width: 2px 0 2px 0 !important;
  border:       solid $grey_level_3;

  // to center vertically
  .slick-slide {
    display:        inline-block;
    float:          none;
    vertical-align: middle;
    overflow:       hidden;
  }
}

.slider-5-img {
  .slick-slide img {
    margin:    0 auto;
    max-width: 140px;
    font-size: 9px;

    @media only screen and (min-width: $screen-md-min) {
      max-width: 100px;
    }

    @media only screen and (min-width: $screen-lg-min) {
      max-width: 80px;
    }
  }

}

.categories-slider {
  padding:    15px 0;
  border-top: 2px solid $grey_level_3;
  height: 290px;

  .slick-list, .slick-track {
    height: 100%;
  }

  img.categories-img {
    max-width: 95%;
    max-height: 180px;
    margin-top: -50px;
    @media only screen and (min-width: $screen-lg-min) {
      max-width: 65%;
    }      
  }

  .slick-slide {
    background-color: #F2F2F2;
    margin: 0 5px;
    div {
      height: 100%;
    }

    .category-item {
      position: relative;
      overflow: hidden;
      &:hover {
        background-color: #898989;
        box-shadow: 0 0 20px rgba(0,0,0,0.4);
  
        img.categories-img {
          mix-blend-mode: multiply;
        }

        h4 {
          color: #fff;
        }
      }
      &:active {
        background-color: #666;
      }      
      .category_img_wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;        
      }
      a {
        display: block;
        height: 100%;

        h4 {
          position: absolute;
          bottom: 30px;
          width: 100%;
          text-transform: uppercase;
          text-align: center;
          padding: 0;
          font-size: 13px;
          @media only screen and (min-width: $screen-lg-min) {
            padding: 0 20px;
            font-size: 14px;
          }          
        }
      }
    }
  }
}

/*----- Home page featured products slider ----- */

.featured-slider {
  padding: 15px 0;
}

.slick-slider {
  .slick-list {
    margin: 0 30px;
  }

  .slick-prev, .slick-next {
    z-index:     100;
    display:     inline-block;
    width:       24px;
    height:      24px;
    position:    absolute;
    line-height: 0;
    font-size:   0;
    cursor:      pointer;
    top:         50%;
    transform:   translate(0, -50%);
    padding:     0;
    background:  0 0;

    &:before {
      content:      '';
      display:      inline-block;
      padding:      8px;
      border-width: 0 0 4px 4px !important;
      border:       solid $bg_disabled;
    }

    &:hover {
      &:before {
        border-color: $bg_hover;
      }
    }
  }

  .slick-prev {
    left: 2px;

    &:before {
      transform: rotate(45deg);

    }
  }

  .slick-next {
    right: 2px;

    &:before {
      transform: rotate(-135deg);
    }
  }

  .slick-dots {
    display:    block;
    width:      100%;
    padding:    0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display:  inline-block;
      width:    12px;
      height:   12px;
      margin:   0 5px;
      padding:  0;
      cursor:   pointer;

      button {
        font-size:   0;
        line-height: 0;
        display:     block;
        width:       12px;
        height:      12px;
        padding:     5px;
        cursor:      pointer;
        color:       transparent;
        border:      0;
        outline:     none;
        background:  transparent;

        &:before {
          display:                 inline-block;
          content:                 '';
          position:                absolute;
          top:                     0;
          left:                    0;
          width:                   12px;
          height:                  12px;
          border:                  1px solid $grey_level_4;
          border-radius:           12px;
          background:              $grey_level_4;
          text-align:              center;
          color:                   black;
          transition:              0.5s all;
          transition-property:     border-color, background;

          -webkit-font-smoothing:  antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        &:hover:before,
        &:focus:before {
          background:    $grey_level_1;
          border-color:  $grey_level_1;
          border-radius: 12px;
        }
      }

      &.slick-active button:before {
        background:   $grey_level_1;
        border-color: $grey_level_1;
      }
    }
  }

  h3, h4, h5 {
    text-align: center;
  }
}

// NEW SLIDER FOR HOME: products line

.carousel_products_line {
  width: 100%;
  margin: 50px 0;
  .carousel_products_line-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 20px 0;
    border-bottom: solid 2px #ddd;
    > * {
      position: relative;
      top: 2px;
    }
    h5 {
      text-transform: uppercase;
      font-weight: bolder;
      font-size: 16px;
      border-bottom: solid 2px $brand_red;
      padding: 0 10px 7px 0;
      margin: 0;
    }
    .carousel_products_line-arrows {
      display: flex;
      button {
        width: 40px;
        height: 40px;
        padding: 0;
        border-bottom: solid 2px $brand_red;
        &:not(:last-child) {
          margin-right: 2px;
        }
        &:hover,
        &:active,
        &:focus {
          background: $lightgrey;
        }
        i {
          color: $black;
        }
      }
    }
  }

  .carousel_products_line-slider {
    .slick-slide {
      margin: 0 5px;
      width: calc(100% / 4);
    }
    .slick-list {
      margin: 0;
    }
    .slick-list,
    .slick-track {
      min-width: 100%;
    }
    ul {
      display: flex;
      list-style-type: none;
      padding: 1px;
      li {
        margin: 0;
        width: calc(100% / 4);
        display: block;
        height: 100%;
        border: solid 1px #ddd;
        padding: 10px;
        &:hover,
        &:active,
        &:focus {
          background-color: #f5f5f5;
          h6 {
            a { 
              color: $brand_red;
            }
          }
        }        
        .img {
          height: 80%;
          img {
            display: block;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            mix-blend-mode: multiply;
          }
        }
        h6 {
          text-align: center;
          text-transform: uppercase;
          min-height: 30px;
          height: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          a {
            color: $black;
            display: block;
            &:hover,
            &:active,
            &:focus {
              text-decoration: none;
            }
          }
        }
        .carousel_products_line-price {
          color: $black;
          border-radius: 3px;
          text-align: center;
          text-transform: uppercase;
          padding: 2px 0;
          p {
            font-size: 11px;
            margin: 0;
          }
          .cc_price_value {
            color: $brand_red;
            font-weight: bolder;
            font-size: 13px;
          }
        }
      }
    }
  }
}