.whatsappButton {
  position: fixed;
  right: 20px;
  bottom: 35px;
  z-index: 999999;
}

.fa-rotate::before {
  content: "\f2f1";
}

.faIncrease {
  font-size: 14px;
}

.enableClick {
  cursor: pointer;
}

header {
  .osf_menu_container {
    flex: 2;
  }

  .paymentAndShippingContainer {
    flex: 1;
  }

  .paymentAndShippingContainer .fieldContainer {
    display: flex;
    align-items: flex-end;
  }

  .paymentAndShippingContainer .fieldContainer select:required:invalid {
    border: 1px solid red;
    color: red;
  }

  .container {
    position: relative;
  }

  .navbar-inverse {
    padding:          0 0 10px 0;

    .cc_navbar_col_misc {
      .navbar-header {
        float: none;

        .navbar-nav {
          width: 100%;
          float: none;

          .cc_eff_account {
            float:       right;
            padding-top: 13px;

            a {
              line-height: 1em;
            }
          }
        }
      }
    }

    .navbar-nav {
      //margin: 10px 0 0 0;
      @media only screen and (max-width: $screen-sm-max) {
        padding: unset;
        margin: unset;
        float: unset;
      }

      & > li {
        @media only screen and (max-width: $screen-sm-max) {
          // display:    block;
          // text-align: right;
        }

        & > a {
          color:           $black;
          padding:         0 0 0 10px;
          line-height:     45px;
          display:         block;
          letter-spacing:  0.05em;
          font-size:       0.7em;
          text-decoration: none;

          @media only screen and (max-width: $screen-sm-max) {
            line-height: 30px;
            text-align:  left;
          }

          &:hover,
          &:focus {
            @media only screen and (min-width: $screen-md-min) {
              color: $brand_red;
            }
          }
        }
      }
    }

    .navbar-default {
      margin-right: -10px;
      @media only screen and (max-width: $screen-sm-max) {
        margin-left: -25px;
      }      
    }

    .navbar-toggle {
      right: 10px;
    }

    .cc_navbar_collapse {
      @media only screen and (max-width: $screen-sm-max) {
        background-color: $black;
      }
    }
  }

  #logoUrl {    
    display: block;
    width: 100%;
    height: 55px;
    margin-top: 15px;

    @media only screen and (max-width: $screen-sm-max) {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }

  .cc_store_logo_img {
    width:             100%;
    height:            100%;
    background-image:  url("../images/cobralogo-20220404.png");
    background-repeat: no-repeat;
    background-size:   contain;
    background-position: center bottom;
  }
}

p.header-spacer {
  margin: 0;
}

.navbar-inverse {

  .fa-pencil {
    background-color: #989898;
    border-radius:    50%;
    color:            $grey_level_3;
    width:            25px;
    margin-right:     3px;
    padding-top:      1px;
    text-align:       center
  }

  .navbar-nav .fa-pencil:before {
    color:       $grey_level_3;
    font-size:   15px;
    display:     block;
    line-height: 21px;
    text-align:  center
  }

  .navbar-form {
    .form-control {
      display:          block;
      width:            100%;
      height:           30px;
      padding:          7px 12px;
      font-size:        14px;
      line-height:      1.42857143;
      color:            #CCCCCC;
      background-color: transparent;
      background-image: none;
      box-shadow:       none;
      border:           none;
      border-radius:    0;
    }

    .btn {
      padding:          0 14px 4px 39px;
      margin-bottom:    0;
      background-image: none;
      border:           1px solid transparent;
      border-radius:    0;
    }

    .btn-default {
      background-color: transparent;
      border-left:      none;
      border-color:     $grey_level_1;
    }
  }
}

/* ----- Header Styles - Menu -----*/

.cc_col_logo, .cc_navbar_col_misc {
  flex: 1
}

.osfMenuRow {

  .osfMenuContainer {
    position: relative;
    display: flex;
    align-items: flex-end;
  }

  
  @media only screen and (max-width: $screen-sm-max) {
    .osf_menu_container:not(.mobileMenuContainer) {
      display: none;
    }
  }

  .mobileMenuContainer {
    display: none;

    @media only screen and (max-width: $screen-sm-max) {
      width:    15%;
      display: unset;
    }
  }

  .cc_col_search {
    margin-top: 15px;
    flex: 2;
    pointer-events: none;

    @media only screen and (max-width: $screen-sm-max) {
      width: 85%;
      float: left;
      margin-top: 13px;
      padding: unset;
    }

    .cc_input-group {
      width: 100%;

      .input-group-btn {
        width:          34px;
        pointer-events: auto;
      }
    }
  }
}

.cc_payment-shipping-header {
  margin-top: 30px;

  .form-group {
    margin-bottom: 8px;
  }

  .form-control {
    padding: 0 5px;
    border: none;
    background: none;
    text-align: end;
    font-family: 'Montserrat-Regular';
  }
}

.loginoutsec {
  .cc_goto_login,
  .cc_do_logout,
  .cc_login {
    font-weight: 200 !important;
    font-size: .8em !important;
    color: black !important;
    font-family: 'Montserrat-Regular' !important;
  }

  .cc_goto_login:hover,
  .cc_do_logout:hover,
  .cc_login:hover {
    color: $brand_red !important;
  }
}

.cc_my_account {
  #goToAccount {
    font-weight: 200;
    font-size: .8em;
    color: black;
    font-family: 'Montserrat-Regular';
  }

  #goToAccount:hover {
    color: $brand_red;
  }
}

#searchText {
  border-radius:    0;
  height:           37px;
  background-color: white;
  font-size:        .8em;
  font-weight:      400;
  color:            $black;
  margin:           0;
  float:            right;
  pointer-events:   auto;

  /*&:focus {
    width: 100%;
  }*/

  &::placeholder {
    font-size: 1em;
  }
}

.navbar-default {
  margin:           0;
  padding:          0;
  background-color: transparent;
  border:           none;

  .navbar-toggle {
    .icon-bar {
      background-color: $grey_level_2;
    }
  }

  .cc_navbar-nav {
    float: none;

    @media only screen and (min-width: $screen-md-min) {
      float: left;
    }

    li.dropdown {
      a {
        font-size:      .9em;
        font-weight:    500;
        text-transform: capitalize;
        line-height:    45px;
        display:        block;
        padding:        5px 15px 0;     
      }

      &.open a,
      a:hover,
      a:focus,
      a:active {
        @media only screen and (min-width: $screen-md-min) {
          color: $brand_red;
        }
      }

      .dropdown-menu {
        margin-top:    0;
        border-radius: 0;

        @media only screen and (min-width: $screen-md-min) {
          background:    $grey_level_5;
          box-shadow:    0 3px 12px rgba(0, 0, 0, .175);
        }

        & > li a {
          color:       $black;
          font-size:   .9em;
          font-weight: 600;
          padding:     0 17px;
          line-height: 30px;

          @media only screen and (max-width: $screen-xs-max) {
            color: $white;
            text-transform: uppercase;
            font-size: 12px;
          }

          &:hover {
            background-color: $grey_level_4;
          }
        }

        & > .dropdown-menu {
          margin-top:    -6px;
          margin-left:   0;
          border-radius: 0;
          box-shadow:    0 3px 6px rgba(0, 0, 0, .175);
        }
      }
    }
  }
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  border-color:     transparent;
}

.nav-justified{
  li.cc_checkoutStep{
    &.active {
      a {
        background: $grey_level_1;
      }
    }
  }
}

.breadcrumb {
  padding:          8px 15px 0 0;
  margin:           30px 0 0 0;
  list-style:       none;
  background-color: transparent;
  border-radius:    0;
}

.breadcrumb li a {
  text-transform: uppercase;
  color:          #989898;
  font-size:      11px;
}

.breadcrumb li {
  font-size:      9px;
  text-transform: uppercase;
  color:          #989898;
}

.cc_search_form {
  position:     relative;
  z-index:      1001;

  @media only screen and (max-width: $screen-sm-max) {
    margin-right: 15px;
  }
}

.cc_navbar_col_acct {
  position: relative;
  float:    right;
}

#cc_list_inline i {
  background-color: transparent;
}

.fa {
  width:            15px !important;
  background-color: transparent !important;
}

.cc_do_logout i {
  display: none;
}

.cc_goto_login i {
  display: none;
}

.navbar-inverse .navbar-nav > li > a {
  text-transform: uppercase;
}

.navbar-inverse .modal-content .fa {
  padding: 5px 0 0 2px;
}

.navbar-fixed-bottom.container.cc_container.cc_navbar-fixed-bottom.compareTray {
  bottom:     0;
  padding:    20px 30px 5px;
  box-shadow: 0 1px 19px #999999;
}

#doSearch {
  padding:           0 5px 0 10px;
  background-color:  #FFFFFF;
  border-color:      #FFFFFF;
  border-radius:     0 4px 4px 0;
  background-image:  url("../images/search.svg");
  background-size:   contain;
  background-repeat: no-repeat;
  height:            37px;
}

#doSearch .fa-search:before {
  content: none;
}

.cc_navbar_form {
  padding:    0;
  margin:     0;
  border:     0;
  box-shadow: none;
}

.cc_navbar_row {
  a, span {
    font-weight: normal;
  }
}

.cc_home_slider .carousel-inner {
  margin-bottom: 100px;
  overflow:      unset;
  margin-top:    65px;
}

.cc_breadcrumb_container {
  margin-top: 75px;
}

#effAccounts{

  .modal-dialog{
    @media only screen and (min-width: $screen-md-min) {
      width:  90vw;
    }
  }

  .effaccountTable{
    padding:  0;
    margin: 10px 0;

    th{
      white-space: nowrap;
    }

    tr{
      .pickAccount{
        cursor: pointer;
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
}

.navbar-header {
  .navbar-nav {
    li {
      position: relative;
      padding: 0 2px;
      a {
        text-align: right;
      }
      .qty_balloon {
        position: absolute;
        padding: 4px;
        border-radius: 3px;
        background: #d80212;
        color: #fff;
        line-height: 1;
        top: -4px;
        right: -3px;
        font-size: 7px;
        border: solid 1px #f2f2f2;
      }
      .glyphicon {
        font-size: 21px;
      }
    }
  }
}