@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,300i,400,400i,600,600i,700,700i,900,900i&display=swap');

html, body {
  font-family: "Montserrat-Regular", "Nunito Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h6 {
  font-weight:    700;
  color:          $black;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 14px;
  text-transform: uppercase;
}

h4 a {
  font-weight: 700;
  color:       #000000;
}

h5,
.h5 {
  margin-bottom: 5px;
}

p {
  font-weight: 400;
  font-size:   16px;

  &.small {
    font-weight: 400;
    font-size:   14px;
  }
}

a {
  color:       $brand_red;
  font-weight: 600;

  &:focus{
    outline: none;
  }
}

.cc_product_catalog_panel h4 {
  font-weight: 700;
  color:       #000000;
  text-align:  center;
}

img {
  vertical-align: middle;
  height:         auto;
}

.featured-prod .panel-title {
  font-size: 20px;
}

h5.panel-title {
  position: relative;
}

h4.product_title.cc_product_title {
  text-transform: uppercase;
  font-size:      20px;
  color:          #1B1B1B;
  font-weight:    700;
}

h3.panel-title {
  color: $black;
}

.alert-danger {
  word-break: break-word;
}

button.close {
  &:hover,
  &:focus {
    background-color: #fff;
  }
}

.custom-whishlist-style {
  .pickWish {
    width: 85%;
    height: 50px;
    line-height: 15px;
  }

  .dropdown-toggle {
      width: 15%;
      height: 50px;

    }
}

.cc_wish_buttons {
  .btn-secondary {
    line-height: 19px;
  }

  .caret {
    transform: translateY(-50%);
    margin-right: 10px;
    margin-left: 10px;
  }

  .btn-group {
    & > .btn {
      & + .dropdown-toggle {
        padding: 0;
      }
    }
  }
}

.navbar-inverse {
  background-color: $grey_level_5;
  border: none;
}

/* PLP */
.product-list-page {
  .btn-sort {
    background-color: $white;

    &:hover {
      background-color: $grey_level_4;
    }
  }
}

.no-click {
  pointer-events: none;
}

.btn.cc_add_to_btn,
.btn.cc_update_cart_button,
.btn.cc_add_item,
.btn.cc_add_item {
  line-height: 19px;
}
