button,
input[type="button"],
input[type="submit"],
.btn {
  line-height:      38px;
  height:           38px;
  text-align:       center;
  padding:          0 20px;
  border:           none;
  border-radius:    0;
  outline:          none;
  box-shadow:       none;
  text-transform:   uppercase;
  font-size:        12px;
  background-color: $white; // to overwrite browser button styling

  &:hover,
  &:focus,
  &:active {
    background: $black_hover;
    border:     none;
    outline:    none;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    opacity: 0.3;
  }

  &.small {
    line-height: 3rem;
    height:      3rem;
  }

  &.btn-default {
    color:            $black;
    background-color: $white;

    &:hover {
      text-decoration: underline;
    }
  }

  &.btn-primary,
  &.btn-tertiary {
    color:      $white;
    background: $black;

    &:hover,
    &:focus,
    &:active {
      background: $black_hover;
    }
  }

  &.btn-secondary {
    color:      $black;
    background: $white;
    border:     1px solid $black;

    &:hover,
    &:focus,
    &:active {
      background: $lightgrey;
    }
  }
}

.open {
  > .btn-secondary {
    background: $lightgrey;
  }
}

select,
.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea {
  height:        3rem;
  padding:       0 12px;
  margin:        0;
  border-radius: 0;
  border:        1px solid $black;
  color:         $black;
  transition:    all 0.30s ease-in-out;
  box-shadow:    none;
  outline:       none;

  &:focus:not(select[multiple]){
    border:     2px solid $black;
    box-shadow: none;
  }

  &.error {
    border:     2px solid $black;
    box-shadow: none;
  }

  &::placeholder {
    color: $grey_level_2;
  }
}

select{
  cursor: pointer;
  font: 400 13.3333px Arial;
}

.form-control {
  &:focus {
    border: 2px solid $black;
  }

  option {
    &:hover,
    &:focus,
    &:active {
      background-color: green;
    }

    &:focus {
      outline: $grey_level_4;
    }
  }
}

textarea {
  height: auto;
}

a {
  &:focus,
  &:hover {
    color:   $black;
    outline: none;
  }
}
