footer {
  background-color: $grey_level_5;
}

.container-fluid.navbar-inverse.cc_navbar-inverse {
  position: relative;
  padding:  20px 0 0;
}

.scroll-top {
  position: absolute;
  right:    0;
  bottom:   0;
}

.footer-inner {
  font-size: 16px;

  ul {
    margin:     0;
    padding:    0;
    list-style: none;
  }

  a {
    font-weight: 400;
    color: $black;
    
    &:hover,
    &:focus {
      color: $black;
    }
  }
}

.footer-address {
  .footer-address-item {
    margin-bottom: 10px;
    color: $grey_level_1;
  }

  .fas {
    position: absolute;
    font-size: 22px;
  }

  .footer-address-content {
    margin-left: 45px;
  }
}

.footer-global-links {
  li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.footer-inner {
  .social-icons {
    text-align: center;

    @media print, screen and (min-width: $screen-md-min) {
      text-align:    right;
    }
  }

  .social-icons-list {
    display: inline-flex;

    li {
      &:not(:first-child) {
        margin-left: 12px;
      }

      &:hover,
      &:focus {
        outline: none;
      }

      a {
        display:   flex;
        font-size: 33px;
        color:     $grey_level_2;
      }
    }
  }
}

.footer-logos {
  text-align: center;
  margin-top:    75px;

  img {
    max-width:   120px;
    margin: 10px;
  }

  .salesforce-logo {
    @media print, screen and (min-width: $screen-lg-min) {
      margin-left: 20px;
    }
  }
}

.footer-logos,
.social-icons {
  @media print, screen and (min-width: $screen-md-min) {
    img {
      margin-right: 0;
    }

    margin-right: 15px;
    text-align:    right;
  }

  @media print, screen and (min-width: $screen-lg-min) {
    margin-right: 80px;
  }

  @media print, screen and (min-width: $screen-xl-min) {
    margin-right: 126px;
  }

  a:hover,
  a:focus {
    text-decoration: none;
  }
}

.footer-copy {
  text-align: center;
  font-size:   14px;
  font-weight: 700;
  color:       $grey_level_1;

  @media print, screen and (min-width: $screen_sm_default) {
    text-align:  center;
  }
}

.salesforce-logo {
  height: 50px;
  margin-bottom: 10px;
}

.cc_action_totals {
  button {
    background-color: $white !important;
    color:            #000000 !important;
  }
}

.taxes-price-block {
  color:            $real_black;
  text-align:       center;

  p {
    margin: 0;
  }
}
