.widgetRecentlyBoughtProducts {
  .panel-body {
    ul {
      margin-block-start:   0;
      padding-inline-start: 1em;
      padding-left:         0;
    }
  }
}

.featured-prod {
  display:   flex;
  flex-wrap: wrap;
  width:     100%;

  .panel {
    flex:       1 1 50%;
    max-width:  50%;
    box-sizing: border-box;
    padding:    0 1rem 0 0;
    margin:     0;

    @media only screen and (max-width: $screen-xxs-max) {
      max-width:  100%;
      flex-basis: 100%;
    }
  }
}
