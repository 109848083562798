@font-face {
  font-family: 'Montserrat-Black';
  src: url('../fonts/Montserrat-Black.eot');
  src: url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Montserrat-Black.woff') format('woff'),
       url('../fonts/Montserrat-Black.svg#montserratblack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Montserrat-Regular.woff') format('woff'),
       url('../fonts/Montserrat-Regular.svg#montserratregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.eot');
  src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Montserrat-Bold.woff') format('woff'),
       url('../fonts/Montserrat-Bold.svg#montserratbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
