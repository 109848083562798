body {
  font-size:        16px;
  color:            $black;
  background-color: $white;
}

html {
  overflow-x: hidden;
}

.contentBody.cc_content_body {
  padding:    20px 0 40px 0;
  min-height: 60vh;
  .btn,
  .btn-secondary,
  .btn-primary {
    border-radius: 100px;
  }
  .dropdown-toggle,
  .pickWish {
    border-radius: 3px;
  }
}

@media (min-width: 1001px) {
  .products_1_row_medium_item.cc_product_item .col-md-2 {
    width: 33.33333333%;
  }
  .products_1_row_medium_item.cc_product_item .col-md-5 {
    width: 33.33333333%;
  }
  .col-md-8.col-md-offset-1.cc_main_content_col {
    margin-left: 0;
    width:       75%;
  }
  .col-md-5.col-xs-12.cc_navbar_col_acct {
    width: 47%;
  }
}

@media only screen and (min-width: $screen-xl-min) {
  .container {
    width: 1520px;
  }
}

@media (max-width: $screen-md-max) {
  .cc_col_search .cc_nav {
    right: 0;
  }

  .container-fluid.navbar-inverse.cc_navbar-inverse {
    background-size: 1100px;
  }
  .cc_grid_container .cc_product_container .img-responsive {
    max-width: 100%;
    height: auto;
    max-height: 170px;
  }
  .cc_mini_cart .cc_update_cart_button {
    width: 100%;
  }
  .cc_mini_cart .cc_logged_in_action {
    width: 100%;
  }
  .cc_product_row_actions .wishButtons .btn-group {
    width: 181px;
  }
}

// fix burger menu in between breakpoints
@media only screen and (min-width: $screen_sm_default) and (max-width: $screen-sm-max) { //991
  .container {
    width: auto;
  }

  .navbar-collapse.collapse {
    display: none !important;

    &.in {
      display: block !important;
    }
  }

  .navbar-nav > li {
    float: none;
  }

  .navbar-nav .open .dropdown-menu {
    position:         static;
    float:            none;
    width:            auto;
    margin-top:       0;
    background-color: transparent;
    border:           0;
    box-shadow:       none;
  }
}

@media only screen and (max-width: $screen-xs-max) {
  .paymentAndShippingContainer {
    display: none;
  }

  .osfMenuRow .osfMenuContainer {
    display: unset;
    align-items: unset;
  }

  .container {
    padding: 0 15px;
  }

  .product-list-page{
    .thumbnail{
      padding: 0 15px;
    }
  }

  header {
    .row {
      //margin-left: 160px;
    }

    .container {
      padding: 0 25px;
    }
  }

  .col-md-5.cc_wish_prod_details,
  .gp_quantity_block .cc_text-right {
    text-align: center;
    font-size:  1.3em;
  }

  .navbar-static-top {
    z-index: 1000;
  }
  #searchText {
    width: 100%;
  }
  #searchText:focus {
    width: 100%;
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color:     transparent;
    width:            100%;
    position:         absolute;
    top:              65px;
    left:             0;
    right:            0;
    margin:           0;
    padding:          0;
    background-color: $real_black;
    height:           2000px !important;

    .dropdown a {
      color: $white;
    }
  }

  .navbar-default .cc_navbar-nav {
    display: block;
    margin:  0;
  }
  .cc_home_slider {
    background-color: transparent;
  }
  .navbar-toggle {
    display:          inline-block;
    position:         absolute;
    top:              5px;
    right:            0;
    float:            none;
    padding:          0 10px;
    margin-top:       8px;
    margin-right:     5px;
    margin-bottom:    8px;
    background-color: transparent;
    background-image: none;
    border:           none;
    border-radius:    0;
  }
  .cc_page_controls {
    padding-bottom: 15px;
  }
  .secRelatedAccessories .prodDetRel {
    max-height: 150px;
  }
  .cc_content_body .lliPaymentContainer .row .col-xs-8,
  .cc_content_body .lliPaymentContainer .row .col-xs-4 {
    width: 100%;
  }
  .cc_content_body .lliPaymentContainer .row .col-xs-8 {
    margin: 10px 0;
  }

  .footer-inner {
    padding-right: 25px;
  }

  .footer-inner .row > * {
    margin-bottom: 30px;
  }

  .footer-address {
    margin-top: 0;
  }

  .footer-inner .social-icons {
    margin: 0;
  }

  .footer-logos {
    margin: 25px 0 0 -7px;
  }
}

@media only screen and (max-width:$screen-sm-max) {
  .cc_navbar_col_acct {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    
    .navbar-header {
      width: 100%;
    }
  }
  header {
    .row {
      margin-left: 0;
    }
  }
  .cc_product_results_tagline {
    display:    block;
    float:      none !important;
    margin-top: 10px;
  }
  .secRelatedAccessories .prodDetRel {
    max-height: 150px;
  }

  .contentBody.cc_content_body {
    padding-top: 0;
  }

  .container.cc_main_container {
    margin-top: 0;
  }
}

@media only screen and (max-width: 523px) {
  .datepicker {
    width:    100%;
    left:     0 !important;
    overflow: scroll;
  }
  .secRelatedAccessories .prodDetRel {
    max-height: 50px;
  }
}


@media only screen and (max-width: $screen-sm-max) {
  .cc_navbar_col_misc {
    display: none;
    float:   none;

    .navbar-header {
      float: none;

      .navbar-nav > li {
        float: none;      
      }
    }
  }

  .cc_lssec .cc_navbar_nav {
    position: absolute;
    top:      40px;
    left:     5px;
  }
  .cc_my_chead {
    // top: 40px;
  }
  .cc_loginoutsec {
    // top: 20px;
  }
  .cc_col_search {
    top:     0;
    padding: 20px 0 0 0;

    .cc_nav {
      margin: 0;
    }
  }
  header .cc_menu_container {
    padding-top: 20px
  }
}

// easing

.easing {
  -webkit-transition: all 300ms cubic-bezier(0.250, 0.100, 0.250, 1.000);
  -moz-transition: all 300ms cubic-bezier(0.250, 0.100, 0.250, 1.000);
    -o-transition: all 300ms cubic-bezier(0.250, 0.100, 0.250, 1.000);
       transition: all 300ms cubic-bezier(0.250, 0.100, 0.250, 1.000); /* ease (default) */

-webkit-transition-timing-function: cubic-bezier(0.250, 0.100, 0.250, 1.000);
  -moz-transition-timing-function: cubic-bezier(0.250, 0.100, 0.250, 1.000);
    -o-transition-timing-function: cubic-bezier(0.250, 0.100, 0.250, 1.000);
       transition-timing-function: cubic-bezier(0.250, 0.100, 0.250, 1.000); /* ease (default) */  
}

// helper classes

.quantity_block.gp_quantity_block.cc_quantity_block {
  display: table;
  margin: auto;
  @media all and (min-width: $screen-sm-min) {
    display: initial;
    margin: initial;
  }
}
.product_detail_txt {
  text-align: center;
  @media all and (min-width: $screen-sm-min) {
    text-align: left;
  }
}

.cc_main_prod_image {
  margin-bottom: 20px;
  img {
    display: block;
    margin: auto;
  }
}

.centralize_mobile {
  width: auto;
  display: table;
  margin: auto;
  @media all and (min-width: $screen-sm-min) {
    display: initial;
    margin: initial;
    width: initial;
  }
}