@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,300i,400,400i,600,600i,700,700i,900,900i&display=swap");
@font-face {
  font-family: 'Montserrat-Black';
  src: url("../fonts/Montserrat-Black.eot");
  src: url("../fonts/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Black.woff") format("woff"), url("../fonts/Montserrat-Black.svg#montserratblack") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Regular';
  src: url("../fonts/Montserrat-Regular.eot");
  src: url("../fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Regular.woff") format("woff"), url("../fonts/Montserrat-Regular.svg#montserratregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.svg#montserratbold") format("svg");
  font-weight: normal;
  font-style: normal; }

/* stylelint-disable order/properties-alphabetical-order */
/* stylelint-enable */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

html, body {
  font-family: "Montserrat-Regular", "Nunito Sans", sans-serif; }

h1,
h2,
h3,
h4,
h6 {
  font-weight: 700;
  color: #383838; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 20px; }

h3,
.h3 {
  font-size: 14px;
  text-transform: uppercase; }

h4 a {
  font-weight: 700;
  color: #000000; }

h5,
.h5 {
  margin-bottom: 5px; }

p {
  font-weight: 400;
  font-size: 16px; }
  p.small {
    font-weight: 400;
    font-size: 14px; }

a {
  color: #D80212;
  font-weight: 600; }
  a:focus {
    outline: none; }

.cc_product_catalog_panel h4 {
  font-weight: 700;
  color: #000000;
  text-align: center; }

img {
  vertical-align: middle;
  height: auto; }

.featured-prod .panel-title {
  font-size: 20px; }

h5.panel-title {
  position: relative; }

h4.product_title.cc_product_title {
  text-transform: uppercase;
  font-size: 20px;
  color: #1B1B1B;
  font-weight: 700; }

h3.panel-title {
  color: #383838; }

.alert-danger {
  word-break: break-word; }

button.close:hover, button.close:focus {
  background-color: #fff; }

.custom-whishlist-style .pickWish {
  width: 85%;
  height: 50px;
  line-height: 15px; }

.custom-whishlist-style .dropdown-toggle {
  width: 15%;
  height: 50px; }

.cc_wish_buttons .btn-secondary {
  line-height: 19px; }

.cc_wish_buttons .caret {
  transform: translateY(-50%);
  margin-right: 10px;
  margin-left: 10px; }

.cc_wish_buttons .btn-group > .btn + .dropdown-toggle {
  padding: 0; }

.navbar-inverse {
  background-color: #F2F2F2;
  border: none; }

/* PLP */
.product-list-page .btn-sort {
  background-color: #FFFFFF; }
  .product-list-page .btn-sort:hover {
    background-color: #E0E0E0; }

.no-click {
  pointer-events: none; }

.btn.cc_add_to_btn,
.btn.cc_update_cart_button,
.btn.cc_add_item,
.btn.cc_add_item {
  line-height: 19px; }

/*******************************************************
 * CSS override for B2B Commerce.
 * Override any CSS provided by OOTB B2B Commerce below.
********************************************************/
.cc_deskLayout {
  overflow: hidden; }

/*----- Home Carousel ----- */
.cc_home_slider .carousel {
  position: relative;
  margin-bottom: -140px;
  background: #000000; }

/*----- Products ----- */
.cc_product_item .cc_price .cc_base_price_label {
  text-decoration: line-through; }

.cc_product_item .cc_price .cc_base_price_value {
  text-decoration: line-through; }

.cc_product_item .price .cc_label {
  font-size: 1.5em;
  color: #000000; }

.cc_product_item .price .cc_price {
  font-size: 1.5em;
  color: #000000; }

.cc_product_item .cc_add_to .btn {
  background: url(../images/icon-shop.png) 85% 10px no-repeat #2d2d2d;
  border-radius: 100px;
  height: 50px; }
  @media all and (min-width: 992px) {
    .cc_product_item .cc_add_to .btn {
      text-indent: -10px; } }
  .cc_product_item .cc_add_to .btn:hover {
    background: url(../images/icon-shop.png) 85% 10px no-repeat #D80212;
    font-weight: bolder; }

.cc_grid_container .cc_product_container .cc_grid_product_info {
  clear: both; }

.cc_grid_container .cc_product_container .img-responsive {
  height: 200px;
  max-width: 262px; }

.cc_grid_container .cc_product_container .cc_product_link {
  font-size: 13px; }
  .cc_grid_container .cc_product_container .cc_product_link a {
    color: #383838;
    font-weight: bolder; }

.productListContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.cc_product_sku {
  font-size: 15px; }

.cc_grid_container .cc_product_container .cc_desc {
  overflow: hidden; }

.cc_grid_container .cc_product_container {
  margin-bottom: 20px; }

.pickWish p {
  margin: 0; }

.cart_item_viewport.cc_quick_wishlist_item {
  height: 240px;
  background: white;
  display: flex;
  align-items: center; }

.prodDetailContainer .includedItemsSection .cc_widget_container .cc_quick_wishlist_item .cc_quick_wishlist_item {
  height: inherit;
  display: flex;
  justify-content: center; }

.quick_wishlist_item.cc_quick_wishlist_item .well.center-block {
  margin-bottom: 3px !important;
  background-color: #FFFFFF;
  border: 1px solid #EEEEEE; }

.quick_wishlist_item.cc_quick_wishlist_item .text_center {
  position: relative;
  top: 390px; }

.quick_wishlist_item.cc_quick_wishlist_item {
  margin-top: 10px;
  margin-bottom: 15px; }

/*----- Order Payment Page ----- */
.checkoutContent .lliPaymentContainer .addressEntry {
  background-color: #EEEEEE; }

.addrEditCarousel .subItem .address_selection_display {
  padding: 5px 55px; }

.addrEditCarousel .carousel-inner .carousel-control {
  padding-top: 15px; }

#modalSec .cc_modal_content .addrEditCarousel {
  padding: 15px 0; }

.modal-footer .cancelMove {
  background-color: #000000;
  color: #FFFFFF;
  font-size: 9px; }

#modalSec .form-group .shippingField {
  margin-bottom: 5px; }

/*----- Containers ----- */
/* Display Overrides or hacky stuff that should be done in markup*/
/* Rogue button in equipment set Accessories tab*/
.cc_prt_button_div_dk span {
  color: white; }

.cc_prt_button_div.cc_prt_button_div_dk {
  text-align: start;
  margin-top: 65px; }

.cc_prt_dk_button span {
  color: #FFFFFF; }

/* Rogue button in equipment set Accessories tab*/
/* PDP Product detail fix */
.product_detail_item .form-horizontal .form-group {
  margin: 0; }

.product_detail_item .row > .col-md-10 {
  margin: 0; }

.cc_product_detail .cc_wish_finder .row > .col-md-10 {
  width: 100%; }

/* Mini cart hack */
.cc_mini_cart .row .col-xs-8 {
  width: 58.33333333% !important; }

.cc_mini_cart .row .col-xs-4 {
  width: 41.66666667% !important; }

.cc_mini_cart .cc_items {
  display: flex;
  align-items: center; }

/* Order Templates hack */
.cc_wish_row .gp_quantity_block .cc_qty_control_row > div {
  padding: 0; }

.cc_wish_row div:nth-child(3) {
  padding: 0; }

/* Order Templates hack */
/* Select Textbox Hack */
select.input-sm {
  line-height: 30px; }

span.cc_attr_qty.cc_qty {
  display: block; }

.row.cc_attr_row {
  margin-bottom: 15px; }

/* Select Textbox Hack */
/* Sidebar search box hack */
.search_button .cc_sidebar_search_button {
  height: 30px; }

/* Sidebar search box hack */
/* my account Datepicker higher z-index Hack */
.datepicker {
  z-index: 5000 !important; }

/* my account Datepicker higher z-index Hack */
/* Compare Tray hack */
.compareTray .cc_navbar-fixed-bottom {
  background-color: #FFFFFF;
  box-shadow: 0 0 18px #888888; }

.cc_main_container > span > p > img {
  margin: 0 auto;
  display: block;
  width: 100%; }

/*.featured-prod p.description.cc_description {
    display: none;
}*/
/*.prodDetailContainer .cc_product_detail_container .panel-heading.cc_heading {
    display: none;
}*/
.panel.panel-default.cc_panel.cc_paginator_products_inc .cc_body .row:last-child {
  display: block; }

.panel.panel-default.cc_panel.cc_paginator_products_inc .cc_body .row:nth-child(1) {
  padding: 0 15px; }

.desktop_body div:last-child .panel.panel-default.cc_panel.cc_paginator_products_inc .cc_body .row:first-child {
  display: block; }

.prodDetailContainer .cc_quantity_block .form-group div.col-sm-5,
.prodDetailContainer .cc_quantity_block .form-group div.col-sm-5 input {
  display: inline; }

.cc_main_content_col .cc_main_content_row {
  margin: 0; }

.cc_action_totals button.btn.btn-default.btn-sm.continueShoppingButton.cc_continue_shopping_button,
.cc_action_totals button.btn.btn-default.btn-sm.cc_create_cart_wishlist,
.cc_action_totals button.btn.btn-default.btn-sm.updateCartButton.cc_update_cart_button,
.cc_action_totals a#emailCartLink button.btn.btn-default.btn-sm.remove.cc_email_cart_link_button {
  background-color: #FFFFFF;
  color: #D80212; }

.panel.panel-default.cc_panel.cc_myaccount_profile h3.cc_title {
  margin-bottom: 30px; }

.panel.panel-default.cc_panel.cc_myaccount_profile h3.panel-title.cc_title {
  margin-bottom: 10px; }

/*----- Carousel -----*/
.carousel-control.right,
.carousel-control.left {
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: none;
  width: 10%;
  opacity: .8;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$real_black00', endColorstr='#80000000', GradientType=1); }

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 30px;
  height: 30px;
  margin-top: -10px;
  font-size: 29px;
  font-weight: 100;
  color: #FFFFFF; }

#seller-carousel .carousel-inner {
  position: relative;
  width: 90%;
  overflow: hidden;
  margin: 0 auto; }

#seller-carousel .carousel-control {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 25px;
  font-size: 99px;
  font-weight: normal;
  padding-top: 30px;
  color: #1B1B1B;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: .5; }

#seller-carousel a.right.carousel-control.cc_right_carousel-control {
  left: inherit; }

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  max-width: none;
  height: auto;
  width: 100%; }

/*----- General Assets -----*/
.thumbnail {
  display: block;
  padding: 0px;
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 0px; }

.label-default {
  background-color: #D80212; }

.panel {
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0 0 10px 0; }

.seller-locator-search {
  margin-bottom: 20px; }

.panel.panel-default.cc_panel.cc_wishlist_panel {
  background-color: #F3F3F3;
  padding: 15px 25px;
  margin-bottom: 5px; }

.panel.panel-default.cc_panel.cc_category_tree {
  border-bottom: 2px solid #DDDDDD; }

.cc_category_tree span.pull-right.cc_icon_area {
  position: absolute;
  right: 0;
  top: 10px; }

.panel-heading {
  padding: 5px 0 5px;
  border-bottom: none; }

.panel-title,
h3.cc_section_title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  letter-spacing: 0;
  padding: 10px 0; }

.well {
  min-height: 20px;
  padding: 30px;
  margin-bottom: 20px;
  background-color: #F3F3F3;
  border: none;
  border-radius: 0;
  box-shadow: none; }

.cc_right_col .panel-heading {
  padding-bottom: 0; }

.cc_product_catalog_panel h3.cc_cat_name {
  color: #000000;
  margin-top: 0; }

.panel-body {
  padding: 10px 0 0; }

.panel-footer {
  padding: 0;
  background-color: transparent;
  border-top: none; }

.panel-default > .panel-heading {
  color: #D80212;
  background-color: transparent;
  border-color: #DDDDDD;
  text-transform: uppercase; }

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0px;
  padding: 0 0 2px; }

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #DDDDDD;
  padding-bottom: 10px; }

.img-rounded {
  border-radius: 0px; }

.promotion-box-LeftNav .panel {
  border: none;
  padding-bottom: 0; }

hr {
  border-top: 1px solid transparent;
  margin: 0; }

.cc_content_body {
  padding-top: 30px;
  padding-bottom: 100px; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #1A1A1A; }

.nav-tabs > li > a {
  border-radius: 0;
  color: #676767; }

.tab-content.cc_tab-content {
  padding: 30px 0; }

p.secContentDescription b {
  margin: 20px 0 15px;
  display: block; }

p.secContentDescription ul {
  margin: 10px 0 15px 25px;
  padding: 0; }

p.secContentDescription li {
  margin: 10px 0 0 0; }

.widgetSection .panel-heading.cc_heading {
  border-bottom: 2px solid #676767;
  margin-bottom: 20px; }

.img-thumbnail {
  border-radius: 0; }

.modal-content {
  border: none;
  border-radius: 0;
  padding: 20px;
  text-align: left; }

img.media-object.cc_media-object.img-responsive {
  margin: 0 auto 30px; }

/*----- Buttons -----*/
.btn-success {
  color: #4B9B78; }

.btn-info {
  color: #4B9B78; }

.btn-warning {
  color: #DDA036; }

.btn-danger {
  color: #D80212; }

.form-group .btn {
  margin-top: 20px; }

.input-group-btn .btn {
  padding: 8px 12px 9px;
  margin-bottom: 0;
  margin-top: 0; }

.cc_mini_quick_order button.btn.btn-default.btn-sm.addMore.cc_addmore {
  float: right; }

button.btn.btn-default.btn-xs.add_to_compare_desktop_button.cc_addto_compare {
  background-color: white;
  color: #D80212; }

button.btn.btn-primary.btn-xs.add_to_compare_desktop_button {
  background-color: transparent;
  color: #D80212;
  padding: 5px 0; }

button.btn.btn-primary.btn-sm.addItem.cc_add_item.pull-right {
  width: 143px; }

/*----- Forms -----*/
.quantity .form-control {
  text-align: right;
  background-color: transparent; }

.form-group {
  margin-bottom: 15px;
  text-align: left; }

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #979797;
  opacity: 1;
  /* Firefox */ }

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #979797; }

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #979797; }

/*----- Minicart -----*/
.minicart {
  padding: 0;
  margin-bottom: 30px;
  background-color: #F3F3F3; }

.panel.panel-default.cc_mini_cart {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 10px 20px 20px; }

.minicart .cc_product,
.minicart .cc_qty,
.minicart .cc_total_qty,
.minicart .cc_total_price,
.form-group label {
  /*    padding-bottom: 5px;*/
  font-weight: 600;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 0; }

.mini_cart span.cc_subtotal {
  float: right; }

.minicart .cc_total_price,
.minicart .cc_qty {
  text-align: right; }

.summary.cc_summary {
  padding-top: 15px;
  border-top: 1px solid #CCCCCC;
  margin-top: 15px; }

.minicart button.btn.btn-default.loggedInAction.cc_logged_in_action {
  float: right; }

.form-horizontal .form-group {
  margin-bottom: 5px; }

/*----- Alerts -----*/
.alert-info {
  color: #555555;
  font-style: italic;
  background-color: #D9EDF7;
  border-color: #BCE8F1;
  border-radius: 0; }

.alert-danger {
  color: #D80212;
  background-color: #F2DEDE;
  border: 1px solid #D80212; }

/*----- Homepage custom static content -----*/
.custom-static-content .editableImage {
  text-align: center; }
  .custom-static-content .editableImage img {
    width: auto;
    height: auto;
    max-width: 100%; }

.custom-static-content figure img {
  margin-bottom: 35px; }

.custom-static-content figcaption {
  margin-top: 20px; }

/*----- Wishlist -----*/
.quickwishdesk.cc_quickwishdesk {
  margin: 20px 0 0; }

.cc_addto_cart {
  margin-top: 25px; }

/*----- Product Details -----*/
.secRelatedAccessories .prodDetRel {
  max-height: 200px; }

.sku.cc_sku {
  margin: 10px 0 5px; }

.inventory.cc_inventory {
  margin: 5px 0;
  font-size: 9px; }

.prodDetailContainer .form-horizontal .control-label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: left;
  font-weight: normal; }

label.col-sm-7.control-label.cc_qty {
  width: 20px;
  padding-left: 0;
  padding-right: 0; }

input#qty {
  width: 63px; }

.col-md-2 i.fa {
  text-align: right;
  display: inline-block;
  margin-left: 8px;
  color: #D80212; }

.cc_main_prod_image {
  margin-bottom: 20px; }

.products_1_row_medium_item.cc_product_item {
  margin-bottom: 50px; }

.quick_wishlist.cc_quick_wishlist .row {
  margin: 0px; }

.cc_product_catalog_panel .col-xs-2 {
  width: 100%;
  /*margin-bottom: 40px;*/
  text-align: center; }

.cc_product_catalog_panel .row {
  width: 47.5%;
  float: left;
  margin: 0px 0 10px; }

.cc_product_catalog_panel .row:nth-child(even) {
  float: right; }

.cc_product_catalog_panel img.categoryList {
  /*width: 100%;*/ }

.cc_product_catalog_panel .panel-heading.cc_heading {
  border-top: 1px solid #CCCCCC;
  border-bottom: 2px solid #CCCCCC; }

a.productName.cc_product_name {
  text-transform: uppercase;
  color: #000000;
  font-weight: 900; }

.container-fluid.cc_demo_splash {
  overflow: hidden;
  position: relative;
  width: 100%; }

.cc_compare_text_row .cc_compare_text_col {
  vertical-align: top; }

/*----- Filters -----*/
.cc_filter_breadcrumbs .cc_filter_breadcrumb.label {
  font-size: 100% !important;
  padding: .3em .6em !important;
  margin: 2px 0; }

.cc_filter_breadcrumb_panel .cc_remove_filters {
  padding: 0;
  background-color: transparent;
  color: black;
  text-decoration: underline; }

.noUi-target {
  border-radius: 0px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBBBBB;
  width: 70%;
  background: darkgrey; }

.spec .upperDisp {
  margin-right: 23%;
  font-size: 10px;
  padding: 10px 0;
  display: block; }

.spec .lowerDisp {
  font-size: 10px;
  padding: 8px 0;
  display: block; }

.spec.cc_spec {
  position: relative; }

button.slideFilter.btn.btn-default.btn-xs.cc_slide_filter {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 13px;
  padding: 3px 10px; }

.panel-collapse {
  position: relative; }

.panel.panel-default.cc_panel.cc_panel_filter_inner {
  margin-top: 30px; }

.fa-lg {
  color: #989898; }

i.fa.fa-lg.fa-th-list.listview {
  float: right; }

i.fa.fa-lg.fa-th.gridview {
  float: right;
  margin-left: 10px; }

#categories-right-nav a {
  padding: 0px;
  display: block; }

/*----- Cart -----*/
/*.cartContainer,
.prodDetailContainer {
    padding-right: 70px;
}*/
.cc_cart_item_list .cc_price_block .price.cc_price {
  font-size: 16px;
  color: #1B1B1B; }

.cc_cart_item_list a.prodLink.cc_prod_link {
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  font-size: 13px; }

.cart_item {
  padding: 30px 0;
  margin: 0 0 30px;
  border-bottom: 2px solid #CCCCCC; }

.cart-links {
  font-size: 10px; }

.cc_cart_item_container .cc_cart_item_list .cc_price_block {
  text-align: right; }

/*----- MY ACCOUNT -----*/
.cc_myaccount_nav_list a {
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  font-size: 11px; }

.cc_myaccount_nav_list li {
  padding: 12px 0 10px; }

ul#side_nav {
  margin: 20px 0; }

button.slideFilter.btn.btn-default.btn-xs.cc_slide_filter {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 13px;
  padding: 3px 10px; }

.panel.cc_myaccount_information,
.panel.cc_myaccount_user_information,
.panel.cc_myaccount_contact_information {
  border-bottom: 2px solid #CCCCCC;
  padding-bottom: 40px;
  margin-bottom: 30px; }

.cc_myaccount_information .panel-heading.cc_heading,
.cc_myaccount_user_information .panel-heading.cc_heading {
  margin: 0;
  padding: 0; }

.cc_main_content_col form {
  margin: 30px 0; }

/*----- Table Styles -----*/
.table-responsive {
  background-color: #FFFFFF;
  padding: 30px;
  margin: 30px 0; }

/*thead,
.cc_table_header_row {
    background-color: #8ca1b1;
    color: #fff;
}*/
tr.cc_table_row,
tr {
  border-bottom: 2px solid #CCCCCC; }

.table {
  margin: 0; }

thead.compareRow.compareSKURow.cc_compare_sku_row {
  background-color: transparent; }

th.compareCol.compareSKUCol.cc_compare_sku_col {
  padding: 8px; }

tr.compareRow.compareImageRow.cc_compare_image_row,
tr.compareTextRow.compareNameRow.cc_compare_name_row,
tr.compareTextRow.compareDescRow.cc_compare_text_row,
tr.compareRow.compareAddtInfoRow.cc_compare_add_info_row,
tr.compareRow.compareUomRow.cc_compare_uom_row,
tr.compareRow.comparePriceRow.cc_compare_price_row,
tr.compareRow.compareQtyAvailRow.cc_compare_qty_avail_row,
tr.compareRow.compareAddToRow.cc_compare_addto_row,
thead.compareRow.compareSKURow.cc_compare_sku_row th {
  border: none; }

.compareBasicInfo .form-group .btn {
  margin-top: 15px; }

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td,
td {
  padding: 10px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: none; }

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 0; }

.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.table-hover > tbody > tr:hover {
  background-color: #EAEAEA; }

table .cc_table_header i.fa {
  font-size: 7px;
  color: #FFFFFF; }

#seller-carousel .col-md-3 {
  width: 25%;
  text-align: center; }

/*----- Specs ----- */
.nvRow.cc_nv_row.row .col-xs-6 {
  padding-left: 0; }

p.groupName.cc_group_name {
  padding: 10px; }

.nvRow.cc_nv_row.row {
  padding: 10px 0;
  margin: 0; }

.col-md-6.cc_main_content_col {
  padding: 0 10px; }
  @media only screen and (max-width: 991px) {
    .col-md-6.cc_main_content_col {
      padding: 0; } }

div#seller-carousel .carousel-indicators {
  display: none; }

.secRelatedCrossSell.cc_secRelatedCrossSell {
  text-align: center; }

p.cc_prod_name a,
p.cc_seller_name,
p.name.cc_name {
  text-transform: uppercase;
  color: #000000;
  font-weight: 700;
  font-size: 13px; }

.quick_wishlist.cc_quick_wishlist .cc_wish_row {
  margin: 10px 0;
  border-bottom: 2px solid #CCCCCC;
  padding-bottom: 20px; }

.text-center.cc_current_page {
  padding-top: 5px; }

.cc_pricing.control p.price {
  color: #000000;
  padding-top: 10px;
  font-size: 16px; }

label.cc_items_per_page {
  font-weight: normal; }

.value.cc_value {
  margin-bottom: 5px; }

.dynamicKitSection.cc_dynamic_kit_section .panel {
  margin-bottom: 0;
  padding-bottom: 0; }

.cc_action_totals.pull-right {
  padding-top: 10px; }

.cc_qty_control_row input.btn.btn-default.btn-sm {
  font-size: 11px;
  padding: 9px 8px 7px;
  width: 25px;
  height: 30px; }

.grand_total p {
  color: #000000;
  font-size: 19px;
  margin: 30px 0; }

p.cc_paginator_legend {
  padding-top: 5px; }

.breadcrumb > li + li:before {
  padding: 0 10px 0 15px;
  color: #CCCCCC;
  content: "/\00a0";
  font-size: 14px; }

.img-thumbnail {
  padding: 0;
  border: none;
  border-radius: 0; }

.cc_wish_form .col-md-2.cc_wish_img {
  padding: 0; }

#at4-share,
#at4-soc {
  top: 50px !important;
  bottom: auto; }

.cc_rank_display {
  margin: 5px 0;
  display: block; }

.products_1_row_medium_item.cc_product_item .row.cc_qty_control_row {
  margin-bottom: 5px; }

.cc_tabs_container img {
  width: auto; }

.cc_main_container .cc_quick_wishlist_item img {
  width: auto;
  margin: 0 auto;
  max-width: 100%; }

.cc_quick_wishlist {
  text-align: center; }

img.mainProdImage.prodDetail.img-responsive {
  width: 100%; }

.wishFinder button.btn.btn-default.btn-sm.addItem.cc_add_item,
.wishButtons button#dropWish {
  padding: 4px 7px 2px;
  color: #FFFFFF; }

.col-md-4.col-md-offset-3.wishFinder {
  margin-left: 15px; }

.fa-lg {
  color: #FFFFFF;
  font-size: 1em;
  line-height: 1em;
  vertical-align: 0; }

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus,
.btn:active,
.btn.active {
  outline: none;
  outline-offset: -2px;
  box-shadow: none; }

.panel.panel-default.cc_panel.cc_shopping_cart_discount_panel {
  background-color: #F3F3F3;
  padding: 30px; }

form#couponAddForm {
  margin: 0; }

.cc_myaccount_content.panel-body.cc_body {
  padding: 0; }

button.btn.btn-default.btn-sm.searchFilter.cc_seaerch_filter_btn,
button.btn.btn-default.btn-sm.addItem.cc_add_item,
button.btn.btn-default.btn-sm.addToCart.cc_addtocart,
button.btn.btn-default.btn-sm.addMore.cc_addmore,
button.btn.btn-default.btn-sm.loggedInAction.cc_logged_in_action,
button.btn.btn-default.btn-sm.updateCartButton.cc_update_cart_button,
.wishFinder .cc_action button.btn.btn-default.btn-sm.addItem.cc_add_item,
input.btn.btn-default.btn-sm.register.cc_register,
#collapseSellerLocatorSearch input.btn.btn-default.btn-sm.search-button.cc_search-button,
input.cc_login_button.btn-sm,
button.btn.btn-default.btn-sm.addToCart.cc_add_to_cart,
button.addAddress.btn.btn-default.btn-sm.cc_add_address_btn,
input.btn.btn-default.btn-sm.cc_cancel_addr.cancelAddr,
input.btn.btn-default.btn-sm.saveAddr.cc_save_addr,
input.btn.btn-default.btn-sm.gotoSectionContactInfoEdit.cc_edit_profile,
button.openModalAddr.btn.btn-default.btn-sm.cc_open_modal_addr,
button.deleteAddress.btn.btn-default.btn-sm.cc_delete_addr,
input.btn.btn-default.btn-sm.createTemplate.cc_create_template,
button.btn.btn-default.btn-sm.makePayment.cc_make_payment,
input.btn.btn-default.btn-sm.processReview.proceed.cc_process_review,
input.btn.btn-default.btn-sm.processBack.cc_process_back,
button.btn.btn-default.btn-sm.addItem.cc_add_item.pull-right,
input#submit,
input.btn.btn-default.btn-sm.processLLIShippingInfo.proceed.cc_proceed,
input.btn.btn-default.btn-sm.processReview.proceed.cc_proceed {
  min-width: 100px; }

button.btn.btn-default.btn-sm.searchFilter.cc_seaerch_filter_btn {
  min-width: 0; }

button.btn.btn-default.btn-sm.addToCart.cc_add_to_cart {
  margin-top: 0; }

.col-xs-12.cc_footer_logo_col img {
  max-width: 150px; }

.splash-promo-Desktop img {
  width: 100%; }

.col-md-5.cc_wish_prod_details {
  text-align: left; }

.collapse h5.panel-title,
.collapsing h5.panel-title {
  font-weight: 300; }

.cc_product_catalog_panel .cc_body .col-xs-10 p {
  text-align: center; }

.cc_product_catalog_panel .cc_body .col-xs-10 {
  width: 100%; }

.caret {
  margin-left: 10px; }

.btn .caret {
  margin-left: 10px; }

.cc_right_col .quick-order {
  margin-bottom: 20px; }

header {
  /*    position: fixed;*/
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1050; }

.mini_cart span.cc_cart_item_count,
.mini_cart span.cc_subtotal {
  font-weight: 700; }

.mini_cart .cc_quantity .input.form-control {
  background-color: transparent; }

.cc_mini_quick_order p.cc_caption {
  line-height: 1.2; }

ol.breadcrumb.cc_breadcrumb li:last-child a {
  font-weight: 700; }

.modal-title,
.modal-header h3 {
  margin: 0;
  line-height: 1.42857143;
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  letter-spacing: 0;
  padding: 0; }

.cc_modal.cc_cart_attribute_items_modal .modal-dialog {
  width: 100%; }

a#additionalInfoLink {
  font-size: 10px; }

.filter_breadcrumb_container_main.cc_filter_breadcrumbs {
  margin: 20px 0; }

.filter_breadcrumb_container_main.cc_filter_breadcrumbs .label {
  font-weight: 300;
  margin-right: 10px;
  font-size: 10px; }

span.label.label-default.pull-right.cc_label.cc_check_filter_label {
  font-size: 10px;
  float: right !important; }

.filterContainer .collapse h5.panel-title {
  font-weight: 700; }

.filterContainer .panel-default > .panel-heading {
  border-bottom: 2px solid #CCCCCC; }

.filterContainer .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none;
  padding-bottom: 10px; }

a.productName.detailLin.cc_detail_linkk {
  text-transform: uppercase;
  font-size: 12px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 0px; }

a.remove.cc_remove {
  color: #CCCCCC; }

.noUi-horizontal .noUi-handle {
  border-radius: 50%;
  box-shadow: none;
  border: 1px solid #CCCCCC;
  background-color: #4C788F;
  width: 15px;
  height: 15px; }

.noUi-base {
  margin-top: 10px; }

.noUi-target {
  border-radius: 0px;
  border: none;
  box-shadow: none;
  width: 70%;
  background: #CCCCCC;
  height: 4px;
  margin-top: 4px; }

.noUi-handle:before,
.noUi-handle:after {
  display: none; }

.noUi-origin {
  border-radius: 0;
  top: -3px; }

.listPaginationSec .col-xs-4 {
  padding: 0; }

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 0px;
  margin-top: 10px; }

button.btn.btn-default.btn-sm.addToCart.cc_add_to_cart {
  margin-top: 0; }

.cc_main_container .cc_dynamic_kit_section .cc_quick_wishlist_item img {
  width: auto;
  margin: 0 auto;
  max-width: 100%;
  max-height: 240px; }

.cc_dynamic_kit_section .cc_name {
  text-align: left;
  height: 34px;
  margin-top: 10px; }

.cc_dynamic_kit_section .form-control[disabled],
.cc_dynamic_kit_section .form-control[readonly],
.cc_dynamic_kit_section fieldset[disabled] .form-control,
.cc_dynamic_kit_section .form-control {
  float: right;
  max-width: 50px; }

input.form-control.input-sm.cc_qty_input {
  max-width: 50px; }

.cc_dynamic_kit_section .cc_pricing.control p.price {
  color: #000000;
  padding-top: 0px;
  font-size: 16px; }

.cc_cart_sort_item .label {
  font-weight: 300; }

.cc_dynamic_kit_section .cc_quick_wishlist {
  border-bottom: 2px solid #CCCCCC;
  margin-bottom: 20px;
  padding-bottom: 10px; }

.dynamicKitSelSection .cc_widget_column {
  padding-bottom: 10px;
  margin-top: 20px; }

.dynamicKitSelSection .col-xs-2 {
  text-align: right; }

.cc_widget_column span {
  font-weight: 700;
  color: #000000; }

img.detailLink.prodDetRel.img-responsive {
  max-width: 200px;
  margin: 0 auto; }

button.close {
  font-size: 25px; }

.includedItemsSection .cc_quick_wishlist {
  border-bottom: 2px solid #CCCCCC;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.includedItemsSection h3.panel-title.cc_title {
  border-bottom: 2px solid #666666;
  margin-bottom: 30px; }

.acctmainSection h3.panel-title.cc_title,
.cc_myaccount_profile h3.cc_title {
  font-size: 20px;
  font-weight: 700; }

.acctmainSection .cc_myaccount_profile h3.panel-title.cc_title {
  font-size: 13px; }

input.form-control.cc_address2 {
  margin: 5px 0; }

.col-md-6.myAccBillingAddr.cc_billing_address,
.col-md-6.myAccShippingAddr.cc_shipping_address,
.col-md-6.myAccMailingAddr.cc_mailing_address,
.col-md-6.myAccOtherAddr.cc_other_address {
  background-color: #EFEFEF;
  padding: 25px 20px;
  width: 47.5%;
  margin-left: 15px;
  margin-top: 20px;
  float: left; }

.col-md-6.myAccShippingAddr.cc_shipping_address {
  float: right; }

.acctmainSection .cc_myaccount_general .cc_shipping_address {
  float: left; }

span.cc_profile_billing_label,
span.cc_profile_shipping_label,
span.cc_profile_other_label,
span.cc_profile_mailing_label {
  background-color: #8CA1B1;
  width: 100%;
  display: block;
  padding: 10px;
  color: #FFFFFF; }

span.cc_profile_billing_value,
span.cc_profile_shipping_value,
span.cc_profile_mailing_value,
span.cc_profile_other_valuel {
  padding: 10px 0;
  display: block; }

p.cc_addr_name {
  font-size: 13px;
  font-weight: 700; }

p.cc_addr_company {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase; }

td.cc_address_control {
  text-align: right; }

.table-hover > tbody > tr:hover {
  background-color: #DDDDDD; }

.status.cc_address_type_value {
  font-weight: 700;
  color: #000000; }

.form-group-sm .form-control {
  border-radius: 0; }

.panel.panel-default.cc_panel.seller-locator.cc_seller_list_panel {
  border-bottom: 2px solid #CCCCCC; }

.nav-justified li.cc_checkoutStep a {
  background-color: #EEEEEE;
  display: block;
  width: 100%;
  border-radius: 0;
  margin: 0;
  height: 50px;
  padding: 0;
  color: #FFFFFF; }

.nav-justified li.cc_checkoutStep.active a {
  background-color: #4C788E; }

.nav-justified li.cc_checkoutStep.active a h4 {
  color: #FFFFFF; }

.nav-justified li.cc_checkoutStep a h4 {
  line-height: 51px;
  font-size: 13px;
  margin: 0;
  padding: 0; }

ul.nav.nav-pills.nav-justified.thumbnail {
  margin-top: 0;
  position: relative;
  top: 20px; }

.cc_compare_tray_controls .col-xs-3.col-xs-offset-6 {
  text-align: right; }

.cc_compareTrayProdSku span.cc_prodSku {
  display: none; }

.prodListSec .cc_heading .col-md-2 {
  float: right;
  text-align: right; }

.row.collapse.compareCollapse:first-child {
  border-bottom: 2px solid #CCCCCC;
  padding-bottom: 5px; }

form#buyerInfo .col-sm-6 {
  padding-left: 0; }

form#buyerInfo .col-sm-6 label {
  margin-top: 10px; }

.cc_compare_tray_name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000000;
  text-align: center; }

.billingAddressPanel.col-xs-4 {
  padding: 10px; }

div#addressCarousel .item .subItem {
  width: 100%; }

div#addressCarousel {
  /*    border: 2px #3b677e;
  border-style: outset;
  padding: 2px;
  margin: 0px;
  background: #fff;*/ }

.panel.panel-default.cc_panel.cc_shipping_group_panel {
  background: #EEEEEE;
  padding: 10px; }

.panel-body.cc_body.cart_summary_body.table-responsive {
  background-color: white;
  padding: 0;
  margin: 0; }

.summaryWidget {
  border: 1px #CCCCCC solid;
  padding: 10px; }

.cc_lliorder_details .panel.panel-default.cc_panel {
  background: #EEEEEE;
  padding: 15px; }

.cc_lliorder_details .addressEntry.col-sm-6.cc_address_entry {
  padding: 0 0 0 15px; }

.invoiceHeaderContainer.row.cc_invoice_header_container {
  background: #EEEEEE;
  padding: 15px;
  margin-bottom: 15px; }

.row.cc_subscription_order_row {
  border: 1px #EEEEEE solid;
  padding: 15px;
  margin-bottom: 5px; }

.row.cc_subscription_order_history {
  background: #EEEEEE;
  padding: 15px; }

.cc_myaccount_mywishlists_container .row.cc_wishlist_item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px #DDDDDD solid; }

.cc_myaccount_mywishlists_container .cart_item {
  padding: 0px;
  margin: 0px;
  border: 0px; }

.addressEntry.col-xs-12 {
  padding-left: 0; }

.prodListSec .cc_product_item .cc_qty_control_row input#qty {
  margin-right: 0;
  float: right; }

.col-md-4.products_1_row_medium_item.cc_product_item {
  border: 1px #EEEEEE solid;
  min-height: 395px; }

.cc_product_item .col-md-12:first-child a.productName.cc_product_name {
  /*height: 165px;
  overflow: hidden;*/
  display: block; }

.cc_product_item .col-md-12 a.productName.cc_product_name {
  min-height: 36px;
  display: block;
  margin-top: 15px; }

.compareTrayProdImageSec.cc_compare_tray_img img {
  max-width: 75px;
  text-align: center;
  margin: 0 auto 10px; }

.cc_compareTrayProdSku button.close {
  font-size: 24px;
  position: absolute;
  color: #000000;
  top: 0;
  right: 0;
  margin: 0 10px 0; }

.grid_buttons button.btn.btn-default.btn-sm.addItem.cc_add_item.pull-right {
  padding: 4px 7px 2px;
  min-width: 58px; }

form#searchInvoiceForm .form-group {
  margin-right: 10px; }

.cc_widget_column span.qty.cc_qty {
  float: left; }

.cc_lli_move_items_panel {
  max-height: none;
  overflow-y: auto; }

.storedPaymentContainer.po.cc_stored_payment_container {
  margin: 20px 0; }

.cc_order_summary p.cc_subtotal {
  margin: 20px 0; }

.cc_order_summary span.pull-left.cc_subtotal_label,
.cc_order_summary span.pull-left.cc_grand_total_label {
  float: none !important; }

.cc_order_summary span.pull-left.cc_grand_total_label,
.cc_order_summary span.pull-right.cc_grand_total_value {
  float: none !important;
  font-size: 19px; }

.cc_order_summary span.pull-right.cc_grand_total_value {
  margin-left: 9px; }

span.pull-right.cc_subtotal_value {
  float: none !important;
  margin-left: 10px; }

.summaryRow {
  font-size: 17px; }

.addressEntry.col-sm-6.cc_address_entry {
  padding: 0 0 20px 0; }

.cc_subscription_image {
  margin-bottom: 20px; }

span.cc_subscription_product_label {
  font-size: 17px;
  text-transform: uppercase;
  color: #000000; }

span.cc_subscription_product_label a.prodLink {
  color: #000000; }

.invoiceContainer.cc_invoice_action_container {
  text-align: left;
  float: left;
  margin: 10px 0 20px; }

.cc_secRelatedCrossSell p.cc_prod_name {
  margin-top: 20px; }

.cc_results_list .cc_add_to .cc_add_to_btn {
  width: 100%;
  max-width: 240px; }

/******************************************************
* TOOLTIP STYLES
*******************************************************/
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #000000;
  text-align: center;
  background-color: #E4E4E4;
  border-radius: 0; }

.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100); }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #E4E4E4; }

.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #E4E4E4; }

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #E4E4E4; }

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #E4E4E4; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #E4E4E4; }

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #E4E4E4; }

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #E4E4E4; }

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #E4E4E4; }

/******************************************************
* TIERED PRICING STYLES
*******************************************************/
.cc_prt_outer_row,
.cc_prt_button_outer {
  background-color: #EEEEEE; }

.cc_prt_outer_row_dk {
  background-color: transparent; }

.cc_prt_outer_row_dk .cc_prt_div_outer {
  background-color: #EEEEEE; }

.cc_prt_button_outer_dk {
  /* background-color: #FFF; */ }

.cc_prt_div_outer.cc_prt_div_outer {
  margin-top: 10px;
  padding-top: 10px; }

.cc_prt_tool_tip_div_dk {
  margin-bottom: 10px; }

.cc_prt_button_outer {
  padding-bottom: 10px; }

.cc_prt_heading_label_div {
  margin-bottom: 10px; }

.cc_prt_tier_box_selected.cc_prt_tier_box_text,
.cc_prt_tier_box_unselected.cc_prt_tier_box_text,
.cc_prt_tier_box_primary.cc_prt_tier_box_text,
.cc_prt_tier_box_overflow.cc_prt_tier_box_text {
  text-align: center;
  padding: 5px;
  margin: 5px 0; }

.cc_prt_tier_box_selected.cc_prt_tier_box_text {
  background-color: #D7D7D7;
  color: #333333;
  border: 1px solid #B3B3B3; }

.cc_prt_tier_box_unselected.cc_prt_tier_box_text {
  background-color: #FFFFFF;
  color: #666666;
  border: 1px solid #DCDCDC; }

.cc_prt_tier_box_text.cc_prt_tier_box_text_range {
  font-weight: 500; }

.cc_prt_tier_box_text.cc_prt_tier_box_text_price {
  font-weight: 700; }

.cc_prt_button.cc_collapse_button {
  background-color: #666666;
  color: #FFFFFF; }

.cc_prt_button.cc_collapse_button:focus {
  outline: none; }

.cc_prt_cart_row,
.cc_prt_outer_row_cart,
.cc_prt_cart_ttrow {
  background-color: #EEEEEE;
  padding: 5px 20px; }

.cc_prt_heading_label {
  margin-top: 10px; }

.cc_prt_tool_tip_div_ct {
  text-align: center; }

.cc_prt_tool_tip_text_ct {
  display: block;
  margin-bottom: 10px; }

.whatsappButton {
  position: fixed;
  right: 20px;
  bottom: 35px;
  z-index: 999999; }

.fa-rotate::before {
  content: "\f2f1"; }

.faIncrease {
  font-size: 14px; }

.enableClick {
  cursor: pointer; }

header .osf_menu_container {
  flex: 2; }

header .paymentAndShippingContainer {
  flex: 1; }

header .paymentAndShippingContainer .fieldContainer {
  display: flex;
  align-items: flex-end; }

header .paymentAndShippingContainer .fieldContainer select:required:invalid {
  border: 1px solid red;
  color: red; }

header .container {
  position: relative; }

header .navbar-inverse {
  padding: 0 0 10px 0; }
  header .navbar-inverse .cc_navbar_col_misc .navbar-header {
    float: none; }
    header .navbar-inverse .cc_navbar_col_misc .navbar-header .navbar-nav {
      width: 100%;
      float: none; }
      header .navbar-inverse .cc_navbar_col_misc .navbar-header .navbar-nav .cc_eff_account {
        float: right;
        padding-top: 13px; }
        header .navbar-inverse .cc_navbar_col_misc .navbar-header .navbar-nav .cc_eff_account a {
          line-height: 1em; }
  @media only screen and (max-width: 991px) {
    header .navbar-inverse .navbar-nav {
      padding: unset;
      margin: unset;
      float: unset; } }
  header .navbar-inverse .navbar-nav > li > a {
    color: #383838;
    padding: 0 0 0 10px;
    line-height: 45px;
    display: block;
    letter-spacing: 0.05em;
    font-size: 0.7em;
    text-decoration: none; }
    @media only screen and (max-width: 991px) {
      header .navbar-inverse .navbar-nav > li > a {
        line-height: 30px;
        text-align: left; } }
    @media only screen and (min-width: 992px) {
      header .navbar-inverse .navbar-nav > li > a:hover, header .navbar-inverse .navbar-nav > li > a:focus {
        color: #D80212; } }
  header .navbar-inverse .navbar-default {
    margin-right: -10px; }
    @media only screen and (max-width: 991px) {
      header .navbar-inverse .navbar-default {
        margin-left: -25px; } }
  header .navbar-inverse .navbar-toggle {
    right: 10px; }
  @media only screen and (max-width: 991px) {
    header .navbar-inverse .cc_navbar_collapse {
      background-color: #383838; } }

header #logoUrl {
  display: block;
  width: 100%;
  height: 55px;
  margin-top: 15px; }
  @media only screen and (max-width: 991px) {
    header #logoUrl {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%; } }

header .cc_store_logo_img {
  width: 100%;
  height: 100%;
  background-image: url("../images/cobralogo-20220404.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom; }

p.header-spacer {
  margin: 0; }

.navbar-inverse .fa-pencil {
  background-color: #989898;
  border-radius: 50%;
  color: #CFCFCF;
  width: 25px;
  margin-right: 3px;
  padding-top: 1px;
  text-align: center; }

.navbar-inverse .navbar-nav .fa-pencil:before {
  color: #CFCFCF;
  font-size: 15px;
  display: block;
  line-height: 21px;
  text-align: center; }

.navbar-inverse .navbar-form .form-control {
  display: block;
  width: 100%;
  height: 30px;
  padding: 7px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #CCCCCC;
  background-color: transparent;
  background-image: none;
  box-shadow: none;
  border: none;
  border-radius: 0; }

.navbar-inverse .navbar-form .btn {
  padding: 0 14px 4px 39px;
  margin-bottom: 0;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0; }

.navbar-inverse .navbar-form .btn-default {
  background-color: transparent;
  border-left: none;
  border-color: #555555; }

/* ----- Header Styles - Menu -----*/
.cc_col_logo, .cc_navbar_col_misc {
  flex: 1; }

.osfMenuRow .osfMenuContainer {
  position: relative;
  display: flex;
  align-items: flex-end; }

@media only screen and (max-width: 991px) {
  .osfMenuRow .osf_menu_container:not(.mobileMenuContainer) {
    display: none; } }

.osfMenuRow .mobileMenuContainer {
  display: none; }
  @media only screen and (max-width: 991px) {
    .osfMenuRow .mobileMenuContainer {
      width: 15%;
      display: unset; } }

.osfMenuRow .cc_col_search {
  margin-top: 15px;
  flex: 2;
  pointer-events: none; }
  @media only screen and (max-width: 991px) {
    .osfMenuRow .cc_col_search {
      width: 85%;
      float: left;
      margin-top: 13px;
      padding: unset; } }
  .osfMenuRow .cc_col_search .cc_input-group {
    width: 100%; }
    .osfMenuRow .cc_col_search .cc_input-group .input-group-btn {
      width: 34px;
      pointer-events: auto; }

.cc_payment-shipping-header {
  margin-top: 30px; }
  .cc_payment-shipping-header .form-group {
    margin-bottom: 8px; }
  .cc_payment-shipping-header .form-control {
    padding: 0 5px;
    border: none;
    background: none;
    text-align: end;
    font-family: 'Montserrat-Regular'; }

.loginoutsec .cc_goto_login,
.loginoutsec .cc_do_logout,
.loginoutsec .cc_login {
  font-weight: 200 !important;
  font-size: .8em !important;
  color: black !important;
  font-family: 'Montserrat-Regular' !important; }

.loginoutsec .cc_goto_login:hover,
.loginoutsec .cc_do_logout:hover,
.loginoutsec .cc_login:hover {
  color: #D80212 !important; }

.cc_my_account #goToAccount {
  font-weight: 200;
  font-size: .8em;
  color: black;
  font-family: 'Montserrat-Regular'; }

.cc_my_account #goToAccount:hover {
  color: #D80212; }

#searchText {
  border-radius: 0;
  height: 37px;
  background-color: white;
  font-size: .8em;
  font-weight: 400;
  color: #383838;
  margin: 0;
  float: right;
  pointer-events: auto;
  /*&:focus {
    width: 100%;
  }*/ }
  #searchText::placeholder {
    font-size: 1em; }

.navbar-default {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none; }
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #959595; }
  .navbar-default .cc_navbar-nav {
    float: none; }
    @media only screen and (min-width: 992px) {
      .navbar-default .cc_navbar-nav {
        float: left; } }
    .navbar-default .cc_navbar-nav li.dropdown a {
      font-size: .9em;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 45px;
      display: block;
      padding: 5px 15px 0; }
    @media only screen and (min-width: 992px) {
      .navbar-default .cc_navbar-nav li.dropdown.open a,
      .navbar-default .cc_navbar-nav li.dropdown a:hover,
      .navbar-default .cc_navbar-nav li.dropdown a:focus,
      .navbar-default .cc_navbar-nav li.dropdown a:active {
        color: #D80212; } }
    .navbar-default .cc_navbar-nav li.dropdown .dropdown-menu {
      margin-top: 0;
      border-radius: 0; }
      @media only screen and (min-width: 992px) {
        .navbar-default .cc_navbar-nav li.dropdown .dropdown-menu {
          background: #F2F2F2;
          box-shadow: 0 3px 12px rgba(0, 0, 0, 0.175); } }
      .navbar-default .cc_navbar-nav li.dropdown .dropdown-menu > li a {
        color: #383838;
        font-size: .9em;
        font-weight: 600;
        padding: 0 17px;
        line-height: 30px; }
        @media only screen and (max-width: 991px) {
          .navbar-default .cc_navbar-nav li.dropdown .dropdown-menu > li a {
            color: #FFFFFF;
            text-transform: uppercase;
            font-size: 12px; } }
        .navbar-default .cc_navbar-nav li.dropdown .dropdown-menu > li a:hover {
          background-color: #E0E0E0; }
      .navbar-default .cc_navbar-nav li.dropdown .dropdown-menu > .dropdown-menu {
        margin-top: -6px;
        margin-left: 0;
        border-radius: 0;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.175); }

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: transparent; }

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: transparent;
  border-color: transparent; }

.nav-justified li.cc_checkoutStep.active a {
  background: #555555; }

.breadcrumb {
  padding: 8px 15px 0 0;
  margin: 30px 0 0 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0; }

.breadcrumb li a {
  text-transform: uppercase;
  color: #989898;
  font-size: 11px; }

.breadcrumb li {
  font-size: 9px;
  text-transform: uppercase;
  color: #989898; }

.cc_search_form {
  position: relative;
  z-index: 1001; }
  @media only screen and (max-width: 991px) {
    .cc_search_form {
      margin-right: 15px; } }

.cc_navbar_col_acct {
  position: relative;
  float: right; }

#cc_list_inline i {
  background-color: transparent; }

.fa {
  width: 15px !important;
  background-color: transparent !important; }

.cc_do_logout i {
  display: none; }

.cc_goto_login i {
  display: none; }

.navbar-inverse .navbar-nav > li > a {
  text-transform: uppercase; }

.navbar-inverse .modal-content .fa {
  padding: 5px 0 0 2px; }

.navbar-fixed-bottom.container.cc_container.cc_navbar-fixed-bottom.compareTray {
  bottom: 0;
  padding: 20px 30px 5px;
  box-shadow: 0 1px 19px #999999; }

#doSearch {
  padding: 0 5px 0 10px;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  border-radius: 0 4px 4px 0;
  background-image: url("../images/search.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 37px; }

#doSearch .fa-search:before {
  content: none; }

.cc_navbar_form {
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none; }

.cc_navbar_row a, .cc_navbar_row span {
  font-weight: normal; }

.cc_home_slider .carousel-inner {
  margin-bottom: 100px;
  overflow: unset;
  margin-top: 65px; }

.cc_breadcrumb_container {
  margin-top: 75px; }

@media only screen and (min-width: 992px) {
  #effAccounts .modal-dialog {
    width: 90vw; } }

#effAccounts .effaccountTable {
  padding: 0;
  margin: 10px 0; }
  #effAccounts .effaccountTable th {
    white-space: nowrap; }
  #effAccounts .effaccountTable tr .pickAccount {
    cursor: pointer; }
  #effAccounts .effaccountTable tr:hover {
    cursor: pointer; }

.navbar-header .navbar-nav li {
  position: relative;
  padding: 0 2px; }
  .navbar-header .navbar-nav li a {
    text-align: right; }
  .navbar-header .navbar-nav li .qty_balloon {
    position: absolute;
    padding: 4px;
    border-radius: 3px;
    background: #d80212;
    color: #fff;
    line-height: 1;
    top: -4px;
    right: -3px;
    font-size: 7px;
    border: solid 1px #f2f2f2; }
  .navbar-header .navbar-nav li .glyphicon {
    font-size: 21px; }

footer {
  background-color: #F2F2F2; }

.container-fluid.navbar-inverse.cc_navbar-inverse {
  position: relative;
  padding: 20px 0 0; }

.scroll-top {
  position: absolute;
  right: 0;
  bottom: 0; }

.footer-inner {
  font-size: 16px; }
  .footer-inner ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .footer-inner a {
    font-weight: 400;
    color: #383838; }
    .footer-inner a:hover, .footer-inner a:focus {
      color: #383838; }

.footer-address .footer-address-item {
  margin-bottom: 10px;
  color: #555555; }

.footer-address .fas {
  position: absolute;
  font-size: 22px; }

.footer-address .footer-address-content {
  margin-left: 45px; }

.footer-global-links li:not(:first-child) {
  margin-top: 10px; }

.footer-inner .social-icons {
  text-align: center; }
  @media print, screen and (min-width: 992px) {
    .footer-inner .social-icons {
      text-align: right; } }

.footer-inner .social-icons-list {
  display: inline-flex; }
  .footer-inner .social-icons-list li:not(:first-child) {
    margin-left: 12px; }
  .footer-inner .social-icons-list li:hover, .footer-inner .social-icons-list li:focus {
    outline: none; }
  .footer-inner .social-icons-list li a {
    display: flex;
    font-size: 33px;
    color: #959595; }

.footer-logos {
  text-align: center;
  margin-top: 75px; }
  .footer-logos img {
    max-width: 120px;
    margin: 10px; }
  @media print, screen and (min-width: 1200px) {
    .footer-logos .salesforce-logo {
      margin-left: 20px; } }

@media print, screen and (min-width: 992px) {
  .footer-logos,
  .social-icons {
    margin-right: 15px;
    text-align: right; }
    .footer-logos img,
    .social-icons img {
      margin-right: 0; } }

@media print, screen and (min-width: 1200px) {
  .footer-logos,
  .social-icons {
    margin-right: 80px; } }

@media print, screen and (min-width: 1600px) {
  .footer-logos,
  .social-icons {
    margin-right: 126px; } }

.footer-logos a:hover,
.footer-logos a:focus,
.social-icons a:hover,
.social-icons a:focus {
  text-decoration: none; }

.footer-copy {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #555555; }
  @media print, screen and (min-width: 768px) {
    .footer-copy {
      text-align: center; } }

.salesforce-logo {
  height: 50px;
  margin-bottom: 10px; }

.cc_action_totals button {
  background-color: #FFFFFF !important;
  color: #000000 !important; }

.taxes-price-block {
  color: #000000;
  text-align: center; }
  .taxes-price-block p {
    margin: 0; }

button,
input[type="button"],
input[type="submit"],
.btn {
  line-height: 38px;
  height: 38px;
  text-align: center;
  padding: 0 20px;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #FFFFFF; }
  button:hover, button:focus, button:active,
  input[type="button"]:hover,
  input[type="button"]:focus,
  input[type="button"]:active,
  input[type="submit"]:hover,
  input[type="submit"]:focus,
  input[type="submit"]:active,
  .btn:hover,
  .btn:focus,
  .btn:active {
    background: #222;
    border: none;
    outline: none;
    box-shadow: none; }
  button:disabled, button.disabled,
  input[type="button"]:disabled,
  input[type="button"].disabled,
  input[type="submit"]:disabled,
  input[type="submit"].disabled,
  .btn:disabled,
  .btn.disabled {
    opacity: 0.3; }
  button.small,
  input[type="button"].small,
  input[type="submit"].small,
  .btn.small {
    line-height: 3rem;
    height: 3rem; }
  button.btn-default,
  input[type="button"].btn-default,
  input[type="submit"].btn-default,
  .btn.btn-default {
    color: #383838;
    background-color: #FFFFFF; }
    button.btn-default:hover,
    input[type="button"].btn-default:hover,
    input[type="submit"].btn-default:hover,
    .btn.btn-default:hover {
      text-decoration: underline; }
  button.btn-primary, button.btn-tertiary,
  input[type="button"].btn-primary,
  input[type="button"].btn-tertiary,
  input[type="submit"].btn-primary,
  input[type="submit"].btn-tertiary,
  .btn.btn-primary,
  .btn.btn-tertiary {
    color: #FFFFFF;
    background: #383838; }
    button.btn-primary:hover, button.btn-primary:focus, button.btn-primary:active, button.btn-tertiary:hover, button.btn-tertiary:focus, button.btn-tertiary:active,
    input[type="button"].btn-primary:hover,
    input[type="button"].btn-primary:focus,
    input[type="button"].btn-primary:active,
    input[type="button"].btn-tertiary:hover,
    input[type="button"].btn-tertiary:focus,
    input[type="button"].btn-tertiary:active,
    input[type="submit"].btn-primary:hover,
    input[type="submit"].btn-primary:focus,
    input[type="submit"].btn-primary:active,
    input[type="submit"].btn-tertiary:hover,
    input[type="submit"].btn-tertiary:focus,
    input[type="submit"].btn-tertiary:active,
    .btn.btn-primary:hover,
    .btn.btn-primary:focus,
    .btn.btn-primary:active,
    .btn.btn-tertiary:hover,
    .btn.btn-tertiary:focus,
    .btn.btn-tertiary:active {
      background: #222; }
  button.btn-secondary,
  input[type="button"].btn-secondary,
  input[type="submit"].btn-secondary,
  .btn.btn-secondary {
    color: #383838;
    background: #FFFFFF;
    border: 1px solid #383838; }
    button.btn-secondary:hover, button.btn-secondary:focus, button.btn-secondary:active,
    input[type="button"].btn-secondary:hover,
    input[type="button"].btn-secondary:focus,
    input[type="button"].btn-secondary:active,
    input[type="submit"].btn-secondary:hover,
    input[type="submit"].btn-secondary:focus,
    input[type="submit"].btn-secondary:active,
    .btn.btn-secondary:hover,
    .btn.btn-secondary:focus,
    .btn.btn-secondary:active {
      background: #EAEAEA; }

.open > .btn-secondary {
  background: #EAEAEA; }

select,
.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea {
  height: 3rem;
  padding: 0 12px;
  margin: 0;
  border-radius: 0;
  border: 1px solid #383838;
  color: #383838;
  transition: all 0.30s ease-in-out;
  box-shadow: none;
  outline: none; }
  select:focus:not(select[multiple]),
  .form-control:focus:not(select[multiple]),
  input[type="text"]:focus:not(select[multiple]),
  input[type="password"]:focus:not(select[multiple]),
  input[type="email"]:focus:not(select[multiple]),
  input[type="tel"]:focus:not(select[multiple]),
  textarea:focus:not(select[multiple]) {
    border: 2px solid #383838;
    box-shadow: none; }
  select.error,
  .form-control.error,
  input[type="text"].error,
  input[type="password"].error,
  input[type="email"].error,
  input[type="tel"].error,
  textarea.error {
    border: 2px solid #383838;
    box-shadow: none; }
  select::placeholder,
  .form-control::placeholder,
  input[type="text"]::placeholder,
  input[type="password"]::placeholder,
  input[type="email"]::placeholder,
  input[type="tel"]::placeholder,
  textarea::placeholder {
    color: #959595; }

select {
  cursor: pointer;
  font: 400 13.3333px Arial; }

.form-control:focus {
  border: 2px solid #383838; }

.form-control option:hover, .form-control option:focus, .form-control option:active {
  background-color: green; }

.form-control option:focus {
  outline: #E0E0E0; }

textarea {
  height: auto; }

a:focus, a:hover {
  color: #383838;
  outline: none; }

.widgetRecentlyBoughtProducts .panel-body ul {
  margin-block-start: 0;
  padding-inline-start: 1em;
  padding-left: 0; }

.featured-prod {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .featured-prod .panel {
    flex: 1 1 50%;
    max-width: 50%;
    box-sizing: border-box;
    padding: 0 1rem 0 0;
    margin: 0; }
    @media only screen and (max-width: 599px) {
      .featured-prod .panel {
        max-width: 100%;
        flex-basis: 100%; } }

/*----- Home page brands slider ----- */
.brands-slider {
  padding: 15px 0;
  border-width: 2px 0 2px 0 !important;
  border: solid #CFCFCF; }
  .brands-slider .slick-slide {
    display: inline-block;
    float: none;
    vertical-align: middle;
    overflow: hidden; }

.slider-5-img .slick-slide img {
  margin: 0 auto;
  max-width: 140px;
  font-size: 9px; }
  @media only screen and (min-width: 992px) {
    .slider-5-img .slick-slide img {
      max-width: 100px; } }
  @media only screen and (min-width: 1200px) {
    .slider-5-img .slick-slide img {
      max-width: 80px; } }

.categories-slider {
  padding: 15px 0;
  border-top: 2px solid #CFCFCF;
  height: 290px; }
  .categories-slider .slick-list, .categories-slider .slick-track {
    height: 100%; }
  .categories-slider img.categories-img {
    max-width: 95%;
    max-height: 180px;
    margin-top: -50px; }
    @media only screen and (min-width: 1200px) {
      .categories-slider img.categories-img {
        max-width: 65%; } }
  .categories-slider .slick-slide {
    background-color: #F2F2F2;
    margin: 0 5px; }
    .categories-slider .slick-slide div {
      height: 100%; }
    .categories-slider .slick-slide .category-item {
      position: relative;
      overflow: hidden; }
      .categories-slider .slick-slide .category-item:hover {
        background-color: #898989;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); }
        .categories-slider .slick-slide .category-item:hover img.categories-img {
          mix-blend-mode: multiply; }
        .categories-slider .slick-slide .category-item:hover h4 {
          color: #fff; }
      .categories-slider .slick-slide .category-item:active {
        background-color: #666; }
      .categories-slider .slick-slide .category-item .category_img_wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
      .categories-slider .slick-slide .category-item a {
        display: block;
        height: 100%; }
        .categories-slider .slick-slide .category-item a h4 {
          position: absolute;
          bottom: 30px;
          width: 100%;
          text-transform: uppercase;
          text-align: center;
          padding: 0;
          font-size: 13px; }
          @media only screen and (min-width: 1200px) {
            .categories-slider .slick-slide .category-item a h4 {
              padding: 0 20px;
              font-size: 14px; } }

/*----- Home page featured products slider ----- */
.featured-slider {
  padding: 15px 0; }

.slick-slider .slick-list {
  margin: 0 30px; }

.slick-slider .slick-prev, .slick-slider .slick-next {
  z-index: 100;
  display: inline-block;
  width: 24px;
  height: 24px;
  position: absolute;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  background: 0 0; }
  .slick-slider .slick-prev:before, .slick-slider .slick-next:before {
    content: '';
    display: inline-block;
    padding: 8px;
    border-width: 0 0 4px 4px !important;
    border: solid #e44e59; }
  .slick-slider .slick-prev:hover:before, .slick-slider .slick-next:hover:before {
    border-color: #b5020f; }

.slick-slider .slick-prev {
  left: 2px; }
  .slick-slider .slick-prev:before {
    transform: rotate(45deg); }

.slick-slider .slick-next {
  right: 2px; }
  .slick-slider .slick-next:before {
    transform: rotate(-135deg); }

.slick-slider .slick-dots {
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center; }
  .slick-slider .slick-dots li {
    position: relative;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-slider .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 12px;
      height: 12px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent; }
      .slick-slider .slick-dots li button:before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 12px;
        height: 12px;
        border: 1px solid #E0E0E0;
        border-radius: 12px;
        background: #E0E0E0;
        text-align: center;
        color: black;
        transition: 0.5s all;
        transition-property: border-color, background;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
      .slick-slider .slick-dots li button:hover:before, .slick-slider .slick-dots li button:focus:before {
        background: #555555;
        border-color: #555555;
        border-radius: 12px; }
    .slick-slider .slick-dots li.slick-active button:before {
      background: #555555;
      border-color: #555555; }

.slick-slider h3, .slick-slider h4, .slick-slider h5 {
  text-align: center; }

.carousel_products_line {
  width: 100%;
  margin: 50px 0; }
  .carousel_products_line .carousel_products_line-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 20px 0;
    border-bottom: solid 2px #ddd; }
    .carousel_products_line .carousel_products_line-header > * {
      position: relative;
      top: 2px; }
    .carousel_products_line .carousel_products_line-header h5 {
      text-transform: uppercase;
      font-weight: bolder;
      font-size: 16px;
      border-bottom: solid 2px #D80212;
      padding: 0 10px 7px 0;
      margin: 0; }
    .carousel_products_line .carousel_products_line-header .carousel_products_line-arrows {
      display: flex; }
      .carousel_products_line .carousel_products_line-header .carousel_products_line-arrows button {
        width: 40px;
        height: 40px;
        padding: 0;
        border-bottom: solid 2px #D80212; }
        .carousel_products_line .carousel_products_line-header .carousel_products_line-arrows button:not(:last-child) {
          margin-right: 2px; }
        .carousel_products_line .carousel_products_line-header .carousel_products_line-arrows button:hover, .carousel_products_line .carousel_products_line-header .carousel_products_line-arrows button:active, .carousel_products_line .carousel_products_line-header .carousel_products_line-arrows button:focus {
          background: #EAEAEA; }
        .carousel_products_line .carousel_products_line-header .carousel_products_line-arrows button i {
          color: #383838; }
  .carousel_products_line .carousel_products_line-slider .slick-slide {
    margin: 0 5px;
    width: calc(100% / 4); }
  .carousel_products_line .carousel_products_line-slider .slick-list {
    margin: 0; }
  .carousel_products_line .carousel_products_line-slider .slick-list,
  .carousel_products_line .carousel_products_line-slider .slick-track {
    min-width: 100%; }
  .carousel_products_line .carousel_products_line-slider ul {
    display: flex;
    list-style-type: none;
    padding: 1px; }
    .carousel_products_line .carousel_products_line-slider ul li {
      margin: 0;
      width: calc(100% / 4);
      display: block;
      height: 100%;
      border: solid 1px #ddd;
      padding: 10px; }
      .carousel_products_line .carousel_products_line-slider ul li:hover, .carousel_products_line .carousel_products_line-slider ul li:active, .carousel_products_line .carousel_products_line-slider ul li:focus {
        background-color: #f5f5f5; }
        .carousel_products_line .carousel_products_line-slider ul li:hover h6 a, .carousel_products_line .carousel_products_line-slider ul li:active h6 a, .carousel_products_line .carousel_products_line-slider ul li:focus h6 a {
          color: #D80212; }
      .carousel_products_line .carousel_products_line-slider ul li .img {
        height: 80%; }
        .carousel_products_line .carousel_products_line-slider ul li .img img {
          display: block;
          margin: auto;
          max-width: 100%;
          max-height: 100%;
          mix-blend-mode: multiply; }
      .carousel_products_line .carousel_products_line-slider ul li h6 {
        text-align: center;
        text-transform: uppercase;
        min-height: 30px;
        height: 20%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .carousel_products_line .carousel_products_line-slider ul li h6 a {
          color: #383838;
          display: block; }
          .carousel_products_line .carousel_products_line-slider ul li h6 a:hover, .carousel_products_line .carousel_products_line-slider ul li h6 a:active, .carousel_products_line .carousel_products_line-slider ul li h6 a:focus {
            text-decoration: none; }
      .carousel_products_line .carousel_products_line-slider ul li .carousel_products_line-price {
        color: #383838;
        border-radius: 3px;
        text-align: center;
        text-transform: uppercase;
        padding: 2px 0; }
        .carousel_products_line .carousel_products_line-slider ul li .carousel_products_line-price p {
          font-size: 11px;
          margin: 0; }
        .carousel_products_line .carousel_products_line-slider ul li .carousel_products_line-price .cc_price_value {
          color: #D80212;
          font-weight: bolder;
          font-size: 13px; }

/* PLP Categories */
.cc_category_row .show-subcat {
  display: block;
  margin: 0 auto;
  text-transform: capitalize;
  padding-top: 7px; }

.cc_category_row .cc_subcategories {
  line-height: 2; }
  .cc_category_row .cc_subcategories a {
    padding-right: 10px;
    text-decoration: none; }
    .cc_category_row .cc_subcategories a:hover, .cc_category_row .cc_subcategories a:focus {
      color: #b5020f;
      text-decoration: underline; }

.toggle-subcategories {
  max-height: 32px;
  overflow: hidden; }

.modal-backdrop:not(.modal + .modal-backdrop) {
  background-image: url("../images/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px; }

/*----- Brands page ----- */
.brands-slider-template a {
  font-weight: 700; }

.brands-slider-template .panel {
  padding-bottom: 0; }

.brands-grid {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }

.brand-link {
  padding: 5px;
  color: #D80212; }
  @media only screen and (min-width: 992px) {
    .brand-link {
      padding: 7px; } }
  .brand-link:focus, .brand-link:hover {
    color: #b5020f;
    outline: none;
    text-decoration-color: #b5020f; }

.brand-img {
  width: 120px; }

.brand-name {
  margin-top: 2px;
  font-weight: 400; }

/* PLP */
.product-list-page .btn-sort {
  background-color: #FFFFFF; }
  .product-list-page .btn-sort:hover {
    background-color: #E0E0E0; }

.product-list-page .description {
  font-size: 12px;
  line-height: 17px; }

@media only screen and (min-width: 992px) {
  .product-list-page .wishButtons .dropdown-menu {
    right: 0;
    left: auto; } }

.product-list-page .modal-dialog .description {
  font-size: 14px;
  height: auto; }

.modal-dialog .btn {
  border-radius: 100px; }

.cc_filter_row {
  padding-left: 15px;
  padding-right: 15px; }

/* My Account */
@media only screen and (min-width: 992px) {
  .acctmainSection {
    padding-right: 50px; } }

.acctmainSection .panel-title {
  display: flex;
  align-items: baseline; }

.acctmainSection .cc_myaccount_profile .row .cc_shipping_address,
.acctmainSection .cc_myaccount_profile .row .cc_billing_address,
.acctmainSection .cc_myaccount_profile .row .cc_mailing_address,
.acctmainSection .cc_myaccount_profile .row .cc_other_address {
  float: none;
  clear: both;
  background-color: #F2F2F2;
  padding: 25px 15px; }

.acctmainSection .cc_myaccount_profile .btn-primary {
  cursor: pointer;
  float: right;
  margin-left: 15px; }

.acctmainSection .cc_myaccount_profile .cc_acct_username_value {
  padding-left: 0; }

.acctmainSection .cc_shipping_address,
.acctmainSection .cc_billing_address {
  width: 100%; }

.acctmainSection .billing_form_title,
.acctmainSection .shipping_form_title,
.acctmainSection .contact_form_title,
.acctmainSection .user_form_title {
  padding-left: 10px;
  font-weight: 700; }

.acctmainSection .billing_block,
.acctmainSection .shipping_block,
.acctmainSection .contact_block,
.acctmainSection .mailing_block,
.acctmainSection .other_block,
.acctmainSection .user_block {
  margin-bottom: 30px; }
  .acctmainSection .billing_block .form-group,
  .acctmainSection .shipping_block .form-group,
  .acctmainSection .contact_block .form-group,
  .acctmainSection .mailing_block .form-group,
  .acctmainSection .other_block .form-group,
  .acctmainSection .user_block .form-group {
    overflow: hidden; }

.acctmainSection span.cc_profile_billing_label,
.acctmainSection span.cc_profile_shipping_label,
.acctmainSection span.cc_profile_other_label,
.acctmainSection span.cc_profile_mailing_label {
  background-color: #959595; }

.acctmainSection .company_address_form,
.acctmainSection .contact_form,
.acctmainSection .user_form {
  display: none;
  border: 1px solid gray;
  padding: 15px; }
  .acctmainSection .company_address_form .form-group:last-child,
  .acctmainSection .contact_form .form-group:last-child,
  .acctmainSection .user_form .form-group:last-child {
    display: flex;
    justify-content: space-between; }
  .acctmainSection .company_address_form .form-group label,
  .acctmainSection .contact_form .form-group label,
  .acctmainSection .user_form .form-group label {
    font-weight: normal;
    padding-left: 0; }
  .acctmainSection .company_address_form input[type=text]:focus,
  .acctmainSection .contact_form input[type=text]:focus,
  .acctmainSection .user_form input[type=text]:focus {
    padding: 0;
    margin: 0; }

.acctmainSection h3.panel-title.cc_title {
  color: #383838; }

/* PDP */
.cc_inventory .cc_value,
.cc_inventory .cc_label {
  font-size: 12px; }

.osf_tmpl_TwoColRight_ProductDetails {
  /*----- Specs ----- */ }
  .osf_tmpl_TwoColRight_ProductDetails .minicart {
    background-color: #F2F2F2; }
    .osf_tmpl_TwoColRight_ProductDetails .minicart h3 {
      color: #383838; }
    .osf_tmpl_TwoColRight_ProductDetails .minicart .cc_summary .form-group.cc_form_group button {
      float: none;
      display: block; }
  .osf_tmpl_TwoColRight_ProductDetails .tabSection .nav-tabs .active a {
    color: #383838; }
  .osf_tmpl_TwoColRight_ProductDetails .tabSection .nav-tabs a {
    color: #959595; }
    .osf_tmpl_TwoColRight_ProductDetails .tabSection .nav-tabs a:hover {
      background-color: #F2F2F2; }
  .osf_tmpl_TwoColRight_ProductDetails .widgetSection .product-desc {
    font-size: 12px; }
    @media print, screen and (max-width: 399px) {
      .osf_tmpl_TwoColRight_ProductDetails .widgetSection .product-desc {
        text-transform: uppercase;
        font-size: 8px; } }
  .osf_tmpl_TwoColRight_ProductDetails .widgetSection .prodDetRel {
    width: 150px; }
  .osf_tmpl_TwoColRight_ProductDetails .sku {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #383838; }
  .osf_tmpl_TwoColRight_ProductDetails .show-scroll {
    height: 592px;
    overflow-y: scroll; }
  .osf_tmpl_TwoColRight_ProductDetails .wishButtons .dropdown-menu {
    right: -1px;
    left: calc(50% - 150px);
    max-width: 500px;
    width: 300px;
    box-sizing: content-box; }
    @media print, screen and (min-width: 992px) {
      .osf_tmpl_TwoColRight_ProductDetails .wishButtons .dropdown-menu {
        left: auto;
        width: calc(100vw / 1.5); } }
    @media print, screen and (min-width: 1200px) {
      .osf_tmpl_TwoColRight_ProductDetails .wishButtons .dropdown-menu {
        width: calc(100vw / 2); } }
  .osf_tmpl_TwoColRight_ProductDetails .wishButtons .pickWish {
    overflow: hidden; }
  .osf_tmpl_TwoColRight_ProductDetails img.mainProdImage.prodDetail.img-responsive {
    width: inherit;
    max-width: 100%; }
  .osf_tmpl_TwoColRight_ProductDetails label.col-sm-7.control-label.cc_qty {
    padding-top: 0;
    width: auto; }
  .osf_tmpl_TwoColRight_ProductDetails .nvRow.nvGroupRow.cc_nv_group_row {
    background-color: #F2F2F2;
    padding: 30px; }
  .osf_tmpl_TwoColRight_ProductDetails .nvRow.cc_nv_row.row .col-xs-6 {
    padding-left: 0; }
  .osf_tmpl_TwoColRight_ProductDetails p.groupName.cc_group_name {
    background-color: #959595;
    padding: 10px;
    color: #fff; }
  .osf_tmpl_TwoColRight_ProductDetails .nvRow.cc_nv_row.row {
    padding: 10px 0;
    margin: 0; }
  .osf_tmpl_TwoColRight_ProductDetails .osf_ProductApplicability_TabContent .cc_group_name_container {
    margin: 0;
    background-color: #F2F2F2; }
  .osf_tmpl_TwoColRight_ProductDetails .osf_ProductApplicability_TabContent .cc_group_name_item {
    padding: 15px;
    margin: 0;
    font-weight: 600; }
  .osf_tmpl_TwoColRight_ProductDetails .osf_ProductApplicability_TabContent .cc_nv_row_spec {
    color: #555555; }

.login-to-buy {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
  align-items: baseline; }
  .login-to-buy .login-to-buy-info {
    display: inline-block;
    line-height: 38px;
    padding-right: 20px;
    padding-left: 20px;
    margin: 15px;
    background-color: #F2F2F2;
    color: #555555; }
  .login-to-buy .btn {
    margin: 15px; }

.quick_wishlist > .row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end; }

.quick_wishlist a {
  outline: none; }

.quick_wishlist .h3 {
  font-size: 14px; }

.quick_wishlist .product-desc {
  height: calc(1em * 3); }

.quick_wishlist .product-brand {
  height: calc(1em * 1.5); }

.quick_wishlist .product-desc,
.quick_wishlist .product-brand {
  overflow: hidden;
  text-transform: none;
  font-size: 10px;
  font-weight: 400; }

.quick_wishlist :before,
.quick_wishlist :after {
  width: 0; }

.product-usage:not(:last-child):after {
  content: '\002C'; }

/* Static pages */
.contactUs img {
  width: 100%; }

.contactUs h1 {
  margin-bottom: 30px; }

.termsAndConditions h1,
.privacyPolicy h1,
.returnAndExchangePolicy h1 {
  text-transform: none; }

/* Order View page */
.cc_order_details .well {
  background-color: #F2F2F2; }

.link_button {
  display: flex;
  justify-content: space-between; }

.checkout-terms {
  padding-top: 15px;
  padding-bottom: 15px; }
  .checkout-terms h4 {
    padding-left: 15px;
    text-transform: none; }

.cc_order_review h5 {
  font-weight: 500;
  font-size: 21px;
  width: 80%;
  border-bottom: solid 1px #ccc;
  padding: 0 0 10px 0;
  margin: 30px 0 15px 0; }

.d-block {
  display: block; }

.cc_order_details .checkout_firstname,
.cc_order_details .checkout_lastname,
.cc_order_details .checkout_company_name,
.cc_order_details .cc_buyer_firstname,
.cc_order_details .cc_buyer_lastname,
.cc_order_details .cc_grand_total,
.cc_checkout_review .checkout_firstname,
.cc_checkout_review .checkout_lastname,
.cc_checkout_review .checkout_company_name,
.cc_checkout_review .cc_buyer_firstname,
.cc_checkout_review .cc_buyer_lastname,
.cc_checkout_review .cc_grand_total {
  font-weight: 700;
  font-size: 16px; }

.cc_checkout_review .messagingSection-Error i {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600; }

/* Checkout Page */
.cc_checkout_user_info .osf_checkout_address_block {
  margin: 1rem 0;
  padding: 0 15px; }

.cc_checkout_user_info h3 {
  margin-top: 30px; }

.cc_checkout_user_info .user-info-title {
  background-color: #EAEAEA;
  border-top: 1px solid #383838;
  border-bottom: 1px solid #383838;
  padding: 10px 15px; }

.cc_checkout_user_info .label {
  display: inline-block;
  min-width: 15em;
  color: #383838;
  font-size: 1em;
  padding: 0;
  text-align: left; }

.cc_checkout_user_info .value {
  display: inline-block; }

.cc_checkout_user_info hr {
  border-top-color: #383838;
  margin-right: 20%;
  margin-top: 20px;
  margin-bottom: 20px; }

.cc_checkout_user_info .shipping-method {
  margin-left: 10px; }

.cc_checkout_user_info .payment-label,
.cc_checkout_user_info .payment-value {
  font-weight: 700; }

.nav-justified .cc_checkoutStep.active a {
  background-color: #555555; }

.osf-invoice-observation-input {
  width: 100%; }

/* Shopping Cart */
.cartContainer .dropdown-menu {
  width: 85vw; }
  @media only screen and (min-width: 768px) {
    .cartContainer .dropdown-menu {
      width: 45vw; } }

.cartContainer .pickWish {
  overflow: hidden;
  text-overflow: ellipsis; }

.cartContainer .osf_checkOutBtn {
  margin-left: 20px; }

.cartContainer a:hover,
.cartContainer input:hover {
  text-decoration: none; }

.cartContainer .cc_subtotal span {
  font-weight: bold; }

.cartContainer .input-group-btn input[type="button"].btn {
  text-decoration: none; }

.shipping-payment-wrapper {
  padding-top: 30px;
  padding-bottom: 30px; }

.shipping-payment-block {
  display: flex;
  flex-direction: column;
  padding-left: 0; }
  .shipping-payment-block .align-right {
    text-align: right; }

.osfCheckCartValidity {
  margin-right: 20px; }

@media only screen and (min-width: 1600px) {
  .cc_cart_item .col-md-4 {
    width: 41.66666667%; }
  .cc_cart_item .col-md-2 {
    width: 8.33333333%; }
  .cc_cart_item .col-md-8.col-md-offset-4 {
    float: right;
    width: 40%;
    margin-left: 0; } }

.cc_cart_item .align-right {
  text-align: right; }
  @media only screen and (max-width: 991px) {
    .cc_cart_item .align-right {
      text-align: left; } }
  .cc_cart_item .align-right button {
    display: inline-block; }

.cc_cart_item .gp_quantity_block input.form-control {
  border-left: none;
  border-right: none; }

.cc_cart_item .gp_quantity_block input.btn.btn-default.btn-sm {
  font-size: 2rem;
  height: 3rem;
  line-height: 3rem;
  padding: 0;
  background-color: #D80212;
  color: #FFFFFF;
  border-radius: 3px; }

.cc_cart_item .gp_quantity_block .input-group-btn:last-child > .btn {
  margin: 0; }

/* HomePage */
.home-page {
  margin-top: 10px; }
  .home-page .splash-promo-Desktop {
    margin-bottom: 10px; }
  .home-page .panel.panel-default.cc_panel.cc_wishlist_panel {
    margin-bottom: 30px; }
    .home-page .panel.panel-default.cc_panel.cc_wishlist_panel .cc_dropdown-toggle {
      padding-right: 30px; }
    .home-page .panel.panel-default.cc_panel.cc_wishlist_panel .cc_wish_img {
      margin-bottom: 10px;
      mix-blend-mode: multiply; }
  .home-page .caret {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 20px; }
  @media only screen and (max-width: 991px) {
    .home-page .cc_main_content_col {
      padding-left: 15px;
      padding-right: 15px; } }
  @media only screen and (max-width: 599px) {
    .home-page .featured-prod .panel {
      padding-right: 0; } }
  .home-page .associatedPages {
    text-align: center;
    display: flex;
    padding: 0px;
    flex-direction: column;
    justify-content: center; }
    @media only screen and (min-width: 600px) {
      .home-page .associatedPages {
        flex-direction: row; } }
    .home-page .associatedPages .associated-item {
      padding: 1px;
      cursor: pointer; }
      .home-page .associatedPages .associated-item img {
        width: 100%;
        max-width: 100%;
        margin: auto;
        display: block; }
    .home-page .associatedPages .modal-header {
      position: relative;
      padding-bottom: 20px; }
    .home-page .associatedPages button.close {
      position: absolute;
      top: 0;
      right: 10px; }
  .home-page .checkPurchases {
    margin-bottom: 15px; }
    .home-page .checkPurchases .check-purchases {
      display: block;
      margin: 0 auto; }
  .home-page .minicart {
    margin-bottom: 0; }
    .home-page .minicart .cc_mini_cart {
      margin-bottom: 30px; }
  .home-page .seller-locator-search {
    margin-bottom: 0; }
  .home-page .quick-order {
    margin-bottom: 0; }

.widgetRecentlyBoughtProducts ul li {
  list-style: none;
  margin: 0 0 15px 0; }
  .widgetRecentlyBoughtProducts ul li .product-name {
    font-size: 13px;
    color: #383838; }

.widgetRecentlyBoughtProducts .product-price,
.widgetRecentlyBoughtProducts .product-name {
  font-weight: normal; }

.osf_cc_cart-template .cc_dropdown-menu {
  max-width: 100%;
  right: auto; }
  @media only screen and (min-width: 992px) {
    .osf_cc_cart-template .cc_dropdown-menu {
      max-width: 50vw;
      left: auto;
      right: 0; } }

.osf_cc_cart-template .cc_dropdown-toggle {
  max-width: 100%;
  text-align: left; }

.osf_cc_cart-template .cc_sel_wish {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.osf_cc_cart-template .cc_pick_quick_wish {
  overflow: hidden;
  text-overflow: ellipsis; }

.cc_promo .cc_body {
  padding-top: 0; }

/* MyCart */
.cc_myaccount_mycart .cc_rename {
  text-decoration: none; }

.cc_product_container {
  background-color: #f2f2f2;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px;
  margin: 0 20px 20px 0;
  width: 100%; }
  @media all and (min-width: 600px) {
    .cc_product_container {
      width: calc(50% - 40px); } }
  @media all and (min-width: 992px) {
    .cc_product_container {
      width: calc(33.33% - 40px); } }
  .cc_product_container .cc_product_item,
  .cc_product_container .thumbnail {
    height: 100%; }
  .cc_product_container .thumbnail {
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .cc_product_container .thumbnail img {
      mix-blend-mode: multiply; }
  .cc_product_container .cc_grid_product_info > * {
    margin: 0 0 5px 0;
    height: auto; }
  .cc_product_container .cc_grid_product_info .cc_product_sku {
    font-weight: bold; }
  .cc_product_container .cc_grid_product_info a {
    color: #383838; }
  .cc_product_container .cc_price {
    font-size: 12px;
    font-weight: bolder; }
  .cc_product_container .cc_qty_control_row {
    display: flex;
    justify-content: center; }

.cc_user_login .panel-body {
  padding: 0; }

.cc_user_login .panel-title {
  padding: 10px 0 25px 0; }

.cc_user_login .alignTextCenter {
  text-align: center; }

.cc_user_login .alignTextRight {
  text-align: right; }

.cc_requestAccess form {
  margin: unset; }

.cc_requestAccess form#requestAccessForm {
  margin-top: 30px; }

.cc_requestAccess h3.session {
  background-color: #F2F2F2;
  padding: 10px;
  border-radius: 5px;
  clear: both; }

.cc_requestAccess .form-group.checkboxFix {
  height: 3rem;
  margin-top: unset; }

.cc_requestAccess select {
  font: unset; }

.cc_requestAccess .fixDivs {
  clear: both; }

.cc_requestAccess div.submitRequest {
  text-align: center;
  margin-top: 10px; }

form.osf-invoice-observation-form {
  padding-top: 15px;
  margin: unset; }

div.available-on-other-store p {
  margin-top: 20px;
  color: red;
  font-weight: bold; }

div.available-store {
  margin-bottom: 10px; }
  div.available-store p.available-store-name {
    font-size: 18px;
    color: black;
    font-weight: bold; }
  div.available-store span.available-store-availability {
    font-weight: lighter;
    font-size: 14px;
    color: red; }
  div.available-store div.actions-another-store {
    margin-top: 15px;
    min-height: 50px;
    text-align: center; }
  div.available-store label {
    padding-top: 0;
    width: auto; }
  div.available-store input {
    width: 63px; }
  div.available-store button {
    line-height: 19px; }

p.different-store-text {
  color: red; }

.center-text {
  text-align: center; }

.custom-credit-card-payment .removePadding {
  padding-left: 0;
  padding-right: 0; }

.custom-credit-card-payment label {
  padding-left: 0; }

.hideElement {
  display: none; }

.text-tag {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #ff9800;
  border-radius: 10px;
  font-size: 14px; }

body {
  font-size: 16px;
  color: #383838;
  background-color: #FFFFFF; }

html {
  overflow-x: hidden; }

.contentBody.cc_content_body {
  padding: 20px 0 40px 0;
  min-height: 60vh; }
  .contentBody.cc_content_body .btn,
  .contentBody.cc_content_body .btn-secondary,
  .contentBody.cc_content_body .btn-primary {
    border-radius: 100px; }
  .contentBody.cc_content_body .dropdown-toggle,
  .contentBody.cc_content_body .pickWish {
    border-radius: 3px; }

@media (min-width: 1001px) {
  .products_1_row_medium_item.cc_product_item .col-md-2 {
    width: 33.33333333%; }
  .products_1_row_medium_item.cc_product_item .col-md-5 {
    width: 33.33333333%; }
  .col-md-8.col-md-offset-1.cc_main_content_col {
    margin-left: 0;
    width: 75%; }
  .col-md-5.col-xs-12.cc_navbar_col_acct {
    width: 47%; } }

@media only screen and (min-width: 1600px) {
  .container {
    width: 1520px; } }

@media (max-width: 1199px) {
  .cc_col_search .cc_nav {
    right: 0; }
  .container-fluid.navbar-inverse.cc_navbar-inverse {
    background-size: 1100px; }
  .cc_grid_container .cc_product_container .img-responsive {
    max-width: 100%;
    height: auto;
    max-height: 170px; }
  .cc_mini_cart .cc_update_cart_button {
    width: 100%; }
  .cc_mini_cart .cc_logged_in_action {
    width: 100%; }
  .cc_product_row_actions .wishButtons .btn-group {
    width: 181px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    width: auto; }
  .navbar-collapse.collapse {
    display: none !important; }
    .navbar-collapse.collapse.in {
      display: block !important; }
  .navbar-nav > li {
    float: none; }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none; } }

@media only screen and (max-width: 991px) {
  .paymentAndShippingContainer {
    display: none; }
  .osfMenuRow .osfMenuContainer {
    display: unset;
    align-items: unset; }
  .container {
    padding: 0 15px; }
  .product-list-page .thumbnail {
    padding: 0 15px; }
  header .container {
    padding: 0 25px; }
  .col-md-5.cc_wish_prod_details,
  .gp_quantity_block .cc_text-right {
    text-align: center;
    font-size: 1.3em; }
  .navbar-static-top {
    z-index: 1000; }
  #searchText {
    width: 100%; }
  #searchText:focus {
    width: 100%; }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border-color: transparent;
    width: 100%;
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    background-color: #000000;
    height: 2000px !important; }
    .navbar-default .navbar-collapse .dropdown a,
    .navbar-default .navbar-form .dropdown a {
      color: #FFFFFF; }
  .navbar-default .cc_navbar-nav {
    display: block;
    margin: 0; }
  .cc_home_slider {
    background-color: transparent; }
  .navbar-toggle {
    display: inline-block;
    position: absolute;
    top: 5px;
    right: 0;
    float: none;
    padding: 0 10px;
    margin-top: 8px;
    margin-right: 5px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: none;
    border-radius: 0; }
  .cc_page_controls {
    padding-bottom: 15px; }
  .secRelatedAccessories .prodDetRel {
    max-height: 150px; }
  .cc_content_body .lliPaymentContainer .row .col-xs-8,
  .cc_content_body .lliPaymentContainer .row .col-xs-4 {
    width: 100%; }
  .cc_content_body .lliPaymentContainer .row .col-xs-8 {
    margin: 10px 0; }
  .footer-inner {
    padding-right: 25px; }
  .footer-inner .row > * {
    margin-bottom: 30px; }
  .footer-address {
    margin-top: 0; }
  .footer-inner .social-icons {
    margin: 0; }
  .footer-logos {
    margin: 25px 0 0 -7px; } }

@media only screen and (max-width: 991px) {
  .cc_navbar_col_acct {
    width: 100%;
    margin-top: 20px;
    text-align: center; }
    .cc_navbar_col_acct .navbar-header {
      width: 100%; }
  header .row {
    margin-left: 0; }
  .cc_product_results_tagline {
    display: block;
    float: none !important;
    margin-top: 10px; }
  .secRelatedAccessories .prodDetRel {
    max-height: 150px; }
  .contentBody.cc_content_body {
    padding-top: 0; }
  .container.cc_main_container {
    margin-top: 0; } }

@media only screen and (max-width: 523px) {
  .datepicker {
    width: 100%;
    left: 0 !important;
    overflow: scroll; }
  .secRelatedAccessories .prodDetRel {
    max-height: 50px; } }

@media only screen and (max-width: 991px) {
  .cc_navbar_col_misc {
    display: none;
    float: none; }
    .cc_navbar_col_misc .navbar-header {
      float: none; }
      .cc_navbar_col_misc .navbar-header .navbar-nav > li {
        float: none; }
  .cc_lssec .cc_navbar_nav {
    position: absolute;
    top: 40px;
    left: 5px; }
  .cc_col_search {
    top: 0;
    padding: 20px 0 0 0; }
    .cc_col_search .cc_nav {
      margin: 0; }
  header .cc_menu_container {
    padding-top: 20px; } }

.easing {
  -webkit-transition: all 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: all 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
  /* ease (default) */
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  /* ease (default) */ }

.quantity_block.gp_quantity_block.cc_quantity_block {
  display: table;
  margin: auto; }
  @media all and (min-width: 992px) {
    .quantity_block.gp_quantity_block.cc_quantity_block {
      display: initial;
      margin: initial; } }

.product_detail_txt {
  text-align: center; }
  @media all and (min-width: 992px) {
    .product_detail_txt {
      text-align: left; } }

.cc_main_prod_image {
  margin-bottom: 20px; }
  .cc_main_prod_image img {
    display: block;
    margin: auto; }

.centralize_mobile {
  width: auto;
  display: table;
  margin: auto; }
  @media all and (min-width: 992px) {
    .centralize_mobile {
      display: initial;
      margin: initial;
      width: initial; } }
