.modal-backdrop:not(.modal + .modal-backdrop) {
  background-image: url("../images/loading.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 25px;
}

/*----- Brands page ----- */
.brands-slider-template {
  a {
    font-weight: 700;
  }

  .panel {
    padding-bottom: 0;
  }
}

.brands-grid {
  text-align:  center;
  display:     flex;
  flex-wrap:   wrap;
  align-items: flex-start;
}

.brand-link {
  padding: 5px;
  color:   $brand_red;

  @media only screen and (min-width: 992px) {
    padding: 7px;
  }

  &:focus,
  &:hover {
    color:                 $bg_hover;
    outline:               none;
    text-decoration-color: $bg_hover;
  }
}

.brand-img {
  width: 120px;
}

.brand-name {
  margin-top:  2px;
  font-weight: 400;
}

/* PLP */
.product-list-page {
  .btn-sort {
    background-color: $white;

    &:hover {
      background-color: $grey_level_4;
    }
  }

  .description {
    font-size: 12px;
    line-height: 17px;
  }

  .wishButtons {
    .dropdown-menu {
      @media only screen and (min-width: $screen-md-min) {
        right: 0;
        left: auto;
      }
    }
  }

  .modal-dialog {
    .description {
      font-size: 14px;
      height: auto;
    }
  }
}

.modal-dialog {
  .btn {
    border-radius: 100px;
  }  
}

.cc_filter_row {
  padding-left: 15px;
  padding-right: 15px;
}

/* My Account */
.acctmainSection {
  @media only screen and (min-width: $screen-sm-min) {
    padding-right: 50px;
  }

  .panel-title {
    display:     flex;
    align-items: baseline;
  }

  .cc_myaccount_profile {
    .row {
      .cc_shipping_address,
      .cc_billing_address,
      .cc_mailing_address,
      .cc_other_address {
        float:            none;
        clear:            both;
        background-color: $grey_level_5;
        padding:          25px 15px;
      }
    }

    .btn-primary {
      cursor:      pointer;
      float:       right;
      margin-left: 15px;
    }

    .cc_acct_username_value {
      padding-left: 0;
    }
  }

  .cc_shipping_address,
  .cc_billing_address {
    width: 100%;
  }

  .billing_form_title,
  .shipping_form_title,
  .contact_form_title,
  .user_form_title {
    padding-left: 10px;
    font-weight:  700;
  }

  .billing_block,
  .shipping_block,
  .contact_block,
  .mailing_block,
  .other_block,
  .user_block {
    margin-bottom: 30px;

    .form-group {
      overflow: hidden;
    }
  }

  span.cc_profile_billing_label,
  span.cc_profile_shipping_label,
  span.cc_profile_other_label,
  span.cc_profile_mailing_label {
    background-color: $grey_level_2;
  }

  .company_address_form,
  .contact_form,
  .user_form {
    display: none;
    border:  1px solid gray;
    padding: 15px;

    .form-group {
      &:last-child {
        display:         flex;
        justify-content: space-between;
      }

      label {
        font-weight: normal;
        padding-left: 0;
      }
    }

    input[type=text]:focus {
      padding: 0;
      margin:  0;
    }
  }

  h3.panel-title.cc_title {
    color: $black;
  }
}

/* PDP */
.cc_inventory {
  .cc_value,
  .cc_label {
    font-size: 12px;
  }
}

.osf_tmpl_TwoColRight_ProductDetails {
  .minicart {
    background-color: $grey_level_5;

    h3 {
      color: $black;
    }

    .cc_summary {
      .form-group.cc_form_group {
        button {
          float: none;
          display: block;
        }
      }
    }
  }

  .tabSection {
    .nav-tabs {
      .active {
        a {
          color: $black;
        }
      }

      a {
        color: $grey_level_2;

        &:hover {
          background-color: $grey_level_5;
        }
      }
    }
  }

  .widgetSection {
    .product-desc {
      @media print, screen and (max-width: $screen-xxxs-max) {
        text-transform: uppercase;
        font-size: 8px;
      }

      font-size: 12px;
    }

    .prodDetRel {
      width: 150px;
    }
  }

  .sku {
    font-weight:    700;
    font-size:      20px;
    text-transform: uppercase;
    color:          $black;
  }

  .show-scroll {
    height:     592px;
    overflow-y: scroll;
  }

  .wishButtons {
    .dropdown-menu {
      right: -1px; //to overlap 'add to cart' button when dropdown is shown
      left: calc(50% - 150px);
      max-width: 500px;
      width: 300px;
      box-sizing: content-box;
      
      @media print, screen and (min-width: $screen-sm-min) {
        left: auto;
        width: calc(100vw / 1.5);
      }

      @media print, screen and (min-width: $screen-lg-min) {
        width: calc(100vw / 2);
      }
    }

    .pickWish {
      overflow: hidden;
    }
  }

  img.mainProdImage.prodDetail.img-responsive {
    width: inherit;
    max-width: 100%;
  }

  label.col-sm-7.control-label.cc_qty {
    padding-top: 0;
    width: auto;
  }

  /*----- Specs ----- */
  .nvRow.nvGroupRow.cc_nv_group_row {
    background-color: $grey_level_5;
    padding: 30px;
  }

  .nvRow.cc_nv_row.row .col-xs-6 {
    padding-left: 0;
  }

  p.groupName.cc_group_name {
    background-color: $grey_level_2;
    padding: 10px;
    color: #fff;
  }

  .nvRow.cc_nv_row.row {
    padding: 10px 0;
    margin: 0;
  }

  .osf_ProductApplicability_TabContent {
    .cc_group_name_container {
      margin: 0;
      background-color: $grey_level_5;
    }
    .cc_group_name_item {
      padding: 15px;
      margin: 0;
      font-weight: 600;
    }

    .cc_nv_row_spec {
      color: $grey_level_1;
    }
  }
}

.login-to-buy {
  display:         flex;
  flex-wrap:       wrap;
  justify-content: flex-end;
  text-align:      right; //to align for IE
  align-items:     baseline;

  .login-to-buy-info {
    display:          inline-block;
    line-height:      38px;
    padding-right:    20px;
    padding-left:     20px;
    margin:           15px;
    background-color: $grey_level_5;
    color:            $grey_level_1;
  }

  .btn {
    margin: 15px;
  }
}

.quick_wishlist > {
  .row {
    display:     flex;
    flex-wrap:   wrap;
    align-items: flex-end;
  }
}

.quick_wishlist {
  a {
    outline: none;
  }

  .h3 {
    font-size: 14px;
  }

  .product-desc {
    height: calc(1em * 3);
  }

  .product-brand {
    height: calc(1em * 1.5);
  }

  .product-desc,
  .product-brand {
    overflow:       hidden;
    text-transform: none;
    font-size:      10px;
    font-weight:    400;
  }

  & :before,
  & :after {
    width: 0;
  }
}

.product-usage {
  &:not(:last-child) {
    &:after {
      content: '\002C';
    }
  }
}

/* Static pages */
.contactUs {
  img {
    width: 100%;
  }

  h1 {
    margin-bottom: 30px;
  }
}

.termsAndConditions,
.privacyPolicy,
.returnAndExchangePolicy {
  h1 {
    text-transform: none;
  }
}

/* Order View page */
.cc_order_details {
  .well {
    background-color: $grey_level_5;
  }
}

.link_button {
  display:         flex;
  justify-content: space-between;
}

.checkout-terms {
  padding-top:    15px;
  padding-bottom: 15px;

  h4 {
    padding-left: 15px;
    text-transform: none;
  }
}

.cc_order_review {
  h5 {
    font-weight: 500;
    font-size: 21px;
    width: 80%;
    border-bottom: solid 1px #ccc;
    padding: 0 0 10px 0;
    margin: 30px 0 15px 0;    
  }
}

.d-block {
  display: block;
}

.cc_order_details,
.cc_checkout_review {
  .checkout_firstname,
  .checkout_lastname,
  .checkout_company_name,
  .cc_buyer_firstname,
  .cc_buyer_lastname,
  .cc_grand_total {
    font-weight: 700;
    font-size: 16px;
  }
}

.cc_checkout_review {
  .messagingSection-Error {
    i {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 600;
    }
  }
}

/* Checkout Page */
.cc_checkout_user_info {
  .osf_checkout_address_block {
    margin: 1rem 0;
    padding: 0 15px;
  }

  h3 {
    margin-top: 30px;
  }

  .user-info-title { 
    background-color: $lightgrey;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    padding: 10px 15px;
  }

  .label {
    display:    inline-block;
    min-width:  15em;
    color:      $black;
    font-size:  1em;
    padding:    0;
    text-align: left;
  }

  .value {
    display: inline-block;
  }

  hr {
    border-top-color: $black;
    margin-right: 20%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .shipping-method {
    margin-left: 10px;
  }

  .payment-label,
  .payment-value {
    font-weight: 700;
  }
}

.nav-justified{
  .cc_checkoutStep{
    &.active{
      a{
        background-color: $grey_level_1;
      }
    }
  }
}

.osf-invoice-observation-input {
  width: 100%; 
}

/* Shopping Cart */
.cartContainer {
  .dropdown-menu {
    width: 85vw;
    @media only screen and (min-width: $screen_sm_default) {
      width: 45vw;
    }
  }

  .pickWish {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .osf_checkOutBtn {
    margin-left: 20px;
  }

  a:hover,
  input:hover {
    text-decoration: none;
  }

  .cc_subtotal  {
    span{
      font-weight: bold;
    }
  }

  .input-group-btn input[type="button"].btn {
    text-decoration: none;
  }
}

.shipping-payment-wrapper {
  padding-top:    30px;
  padding-bottom: 30px;
}

.shipping-payment-block {
  display: flex;
  flex-direction: column;
  padding-left:   0;

  .align-right {
    text-align: right;
  }
}

.osfCheckCartValidity {
  margin-right: 20px;
}

.cc_cart_item {
  @media only screen and (min-width: $screen-xl-min) {
    .col-md-4 {
      width: 41.66666667%;
    }
    .col-md-2 {
      width: 8.33333333%;
    }

    .col-md-8.col-md-offset-4 {
      float:       right;
      width:       40%;
      margin-left: 0;
    }
  }

  .align-right {
    text-align: right;

    @media only screen and (max-width: $screen-sm-max) {
      text-align: left;
    }

    button {
      display: inline-block;
    }
  }

  .gp_quantity_block {

    input.form-control {
      border-left:  none;
      border-right: none;
    }

    input.btn.btn-default.btn-sm {
      font-size:        2rem;
      height:           3rem;
      line-height:      3rem;
      padding:          0;
      background-color: $brand_red;
      color:            $white;
      border-radius: 3px;
    }

    .input-group-btn:last-child > .btn {
      margin: 0;
    }
  }
}

/* HomePage */
.home-page {
  margin-top: 10px;
  .splash-promo-Desktop {
    margin-bottom: 10px;
  }

  .panel.panel-default.cc_panel.cc_wishlist_panel {
    margin-bottom: 30px;

    .cc_dropdown-toggle {
      padding-right: 30px;
    }

    .cc_wish_img {
      margin-bottom: 10px;
      mix-blend-mode: multiply;
    }
  }

  .caret  {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 20px;
  }

  .cc_main_content_col {
    @media only screen and (max-width: $screen-sm-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .featured-prod {
    .panel {
      @media only screen and (max-width: $screen-xxs-max) {
        padding-right: 0;
      }
    }
  }

  .associatedPages {
    text-align: center;
    display: flex;
    padding: 0px;
    flex-direction: column;
    justify-content: center;

    @media only screen and (min-width: $screen-xs-min) {
      flex-direction: row;
    }    

    .associated-item {
      padding: 1px;
      cursor: pointer;

      img {
        width: 100%;
        max-width: 100%;
        margin: auto;
        display: block;
      }
    }

    .modal-header {
      position: relative;
      padding-bottom: 20px;
    }

    button.close {
      position: absolute;
      top: 0;
      right: 10px;
    }
  }

  .checkPurchases {
    margin-bottom: 15px;

    .check-purchases {
      display: block;
      margin: 0 auto;
    }
  }

  .minicart {
    margin-bottom: 0;
    .cc_mini_cart {
      margin-bottom: 30px;
    }
  }

  .seller-locator-search {
    margin-bottom: 0;
  }

  .quick-order {
    margin-bottom: 0;
  }
}

.widgetRecentlyBoughtProducts {
  ul {
    li {
      list-style: none;
      margin: 0 0 15px 0;
      .product-name {
        font-size: 13px;
        color: $black;
      }
    }
  }

  .product-price,
  .product-name {
    font-weight: normal;
  }
}

.osf_cc_cart-template {
  .cc_dropdown-menu {
    max-width: 100%;
    right: auto;

    @media only screen and (min-width: $screen-md-min) {
      max-width: 50vw;
      left: auto;
      right: 0;
    }
  }

  .cc_dropdown-toggle {
    max-width: 100%;
    text-align: left;
  }

  .cc_sel_wish {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cc_pick_quick_wish {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cc_promo {
  .cc_body {
    padding-top: 0;
  }
}

/* MyCart */
.cc_myaccount_mycart {
  .cc_rename {
    text-decoration: none;
  }
}

.cc_product_container {
  background-color: #f2f2f2;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 20px;
  margin: 0 20px 20px 0;
  width: 100%;
  @media all and (min-width: $screen-xs-min) {
    width: calc(50% - 40px);
  }
  @media all and (min-width: $screen-sm-min) {
    width: calc(33.33% - 40px);
  }
  .cc_product_item,
  .thumbnail {
    height: 100%;
  }
  .thumbnail {
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    img {
      mix-blend-mode: multiply;
    }
    .cc_product_grid_actions {
      // margin-top: auto;
    }
  }
  .cc_grid_product_info {
    > * {
      margin: 0 0 5px 0;
      height: auto;
    }

    .cc_product_sku {
      font-weight: bold;
    }

    a {
      color: $black
    }
  }
  .cc_price {
    font-size: 12px;
    font-weight: bolder;
  }
  .cc_qty_control_row {
    display: flex;
    justify-content: center;
  }
}

.cc_user_login {
  .panel-body {
    padding: 0;
  }

  .panel-title {
    padding: 10px 0 25px 0;
  }

  .alignTextCenter {
    text-align: center;
  }

  .alignTextRight {
    text-align: right;
  }
}

.cc_requestAccess {
  form {
    margin: unset;
  }

  form#requestAccessForm {
    margin-top: 30px;
  }

  h3.session {
    background-color: $grey_level_5;
    padding: 10px;
    border-radius: 5px;
    clear: both;
  }

  .form-group.checkboxFix {
    height: 3rem;
    margin-top: unset;
  }

  select {
    font: unset;
  }

  .fixDivs {
    clear: both;
  }

  div.submitRequest {
    text-align:center;
    margin-top: 10px;
  }
}

form.osf-invoice-observation-form {
  padding-top: 15px;
  margin: unset;
}

div.available-on-other-store p {
  margin-top: 20px;
  color: red;
  font-weight: bold;
}

div.available-store {
  margin-bottom: 10px;

  p.available-store-name {
    font-size: 18px;
    color: black;
    font-weight: bold;
  }

  span.available-store-availability {
    font-weight: lighter;
    font-size: 14px;
    color: red;
  }

  div.actions-another-store {
    margin-top: 15px;
    min-height: 50px;
    text-align: center;
  }

  label {
    padding-top: 0;
    width: auto;
  }

  input {
    width: 63px;
  }

  button {
    line-height: 19px;
  }
}

p.different-store-text {
  color: red;
}

.center-text {
  text-align: center;
}

.custom-credit-card-payment {
  .removePadding {
    padding-left: 0;
    padding-right: 0;
  }

  label {
    padding-left: 0;
  }
}

.hideElement {
  display: none;
}

.text-tag {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #ff9800;
  border-radius: 10px;
  font-size: 14px;
}