/*******************************************************
 * CSS override for B2B Commerce.
 * Override any CSS provided by OOTB B2B Commerce below.
********************************************************/

.cc_deskLayout {
  overflow: hidden;
}

/*----- Home Carousel ----- */

.cc_home_slider .carousel {
  position:      relative;
  margin-bottom: -140px;
  background:    $real_black;
}

/*----- Products ----- */

.cc_product_item .cc_price .cc_base_price_label {
  text-decoration: line-through;
}

.cc_product_item .cc_price .cc_base_price_value {
  text-decoration: line-through;
}

.cc_product_item .price .cc_label {
  font-size: 1.5em;
  color:     $real_black;
}

.cc_product_item .price .cc_price {
  font-size: 1.5em;
  color:     $real_black;
}

.cc_product_item {
  .cc_add_to {
    .btn {
      background: url(../images/icon-shop.png) 85% 10px no-repeat #2d2d2d;
      border-radius: 100px;
      height: 50px;
      @media all and (min-width: $screen-sm-min) {
        text-indent: -10px;
      }

      &:hover {
        background: url(../images/icon-shop.png) 85% 10px no-repeat $brand_red;
        font-weight: bolder;
      }
    }
  }
}

.cc_grid_container .cc_product_container .cc_grid_product_info {
  clear: both;
}

.cc_grid_container .cc_product_container .img-responsive {
  height:    200px;
  max-width: 262px;
}

.cc_grid_container .cc_product_container .cc_product_link {
  font-size: 13px;
  a {
    color: $black;
    font-weight: bolder;
  }
}

.productListContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cc_product_sku {
  font-size: 15px;
}

.cc_grid_container .cc_product_container .cc_desc {
  // height:   35px;
  overflow: hidden;
}

.cc_grid_container .cc_product_container {
  margin-bottom: 20px;
}

.pickWish p {
  margin: 0;
}

.cart_item_viewport.cc_quick_wishlist_item {
  height:      240px;
  background:  white;
  display:     flex;
  align-items: center;
}

.prodDetailContainer .includedItemsSection .cc_widget_container .cc_quick_wishlist_item .cc_quick_wishlist_item {
  height:          inherit;
  display:         flex;
  justify-content: center;
}

.quick_wishlist_item.cc_quick_wishlist_item .well.center-block {
  margin-bottom:    3px !important;
  background-color: $white;
  border:           1px solid #EEEEEE;
}

.quick_wishlist_item.cc_quick_wishlist_item .text_center {
  position: relative;
  top:      390px;
}

.quick_wishlist_item.cc_quick_wishlist_item {
  margin-top:    10px;
  margin-bottom: 15px;
}


/*----- Order Payment Page ----- */

.checkoutContent .lliPaymentContainer .addressEntry {
  background-color: #EEEEEE;
}

.addrEditCarousel .subItem .address_selection_display {
  padding: 5px 55px;
}

.addrEditCarousel .carousel-inner .carousel-control {
  padding-top: 15px;
}

#modalSec .cc_modal_content .addrEditCarousel {
  padding: 15px 0;
}

.modal-footer .cancelMove {
  background-color: $real_black;
  color:            $white;
  font-size:        9px;
}

#modalSec .form-group .shippingField {
  margin-bottom: 5px;
}


/*----- Containers ----- */


/* Display Overrides or hacky stuff that should be done in markup*/


/* Rogue button in equipment set Accessories tab*/

.cc_prt_button_div_dk span {
  color: white;
}

.cc_prt_button_div.cc_prt_button_div_dk {
  text-align: start;
  margin-top: 65px;
}

.cc_prt_dk_button span {
  color: $white;
}


/* Rogue button in equipment set Accessories tab*/


/* PDP Product detail fix */

.product_detail_item .form-horizontal .form-group {
  margin: 0;
}

.product_detail_item .row > .col-md-10 {
  margin: 0;
}

.cc_product_detail .cc_wish_finder .row > .col-md-10 {
  width: 100%;
}

/* Mini cart hack */

.cc_mini_cart .row .col-xs-8 {
  width: 58.33333333% !important;
}

.cc_mini_cart .row .col-xs-4 {
  width: 41.66666667% !important;
}

.cc_mini_cart .cc_items {
  display:     flex;
  align-items: center;
}

/* Order Templates hack */

.cc_wish_row .gp_quantity_block .cc_qty_control_row > div {
  padding: 0;
}

.cc_wish_row div:nth-child(3) {
  padding: 0;
}


/* Order Templates hack */


/* Select Textbox Hack */

select.input-sm {
  line-height: 30px;
}

span.cc_attr_qty.cc_qty {
  display: block;
}

.row.cc_attr_row {
  margin-bottom: 15px;
}


/* Select Textbox Hack */


/* Sidebar search box hack */

.search_button .cc_sidebar_search_button {
  height: 30px;
}


/* Sidebar search box hack */


/* my account Datepicker higher z-index Hack */

.datepicker {
  z-index: 5000 !important;
}


/* my account Datepicker higher z-index Hack */


/* Compare Tray hack */

.compareTray .cc_navbar-fixed-bottom {
  background-color: $white;
  box-shadow:       0 0 18px #888888;
}

.cc_main_container > span > p > img {
  margin:  0 auto;
  display: block;
  width:   100%;
}


/*.featured-prod p.description.cc_description {
    display: none;
}*/


/*.prodDetailContainer .cc_product_detail_container .panel-heading.cc_heading {
    display: none;
}*/

.panel.panel-default.cc_panel.cc_paginator_products_inc .cc_body .row:last-child {
  display: block;
}

.panel.panel-default.cc_panel.cc_paginator_products_inc .cc_body .row:nth-child(1) {
  padding: 0 15px;
}

.desktop_body div:last-child .panel.panel-default.cc_panel.cc_paginator_products_inc .cc_body .row:first-child {
  display: block;
}

.prodDetailContainer .cc_quantity_block .form-group div.col-sm-5,
.prodDetailContainer .cc_quantity_block .form-group div.col-sm-5 input {
  display: inline;
}

.cc_main_content_col .cc_main_content_row {
  margin: 0;
}

.cc_action_totals button.btn.btn-default.btn-sm.continueShoppingButton.cc_continue_shopping_button,
.cc_action_totals button.btn.btn-default.btn-sm.cc_create_cart_wishlist,
.cc_action_totals button.btn.btn-default.btn-sm.updateCartButton.cc_update_cart_button,
.cc_action_totals a#emailCartLink button.btn.btn-default.btn-sm.remove.cc_email_cart_link_button {
  background-color: $white;
  color:            $brand_red;
}

.panel.panel-default.cc_panel.cc_myaccount_profile h3.cc_title {
  margin-bottom: 30px;
}

.panel.panel-default.cc_panel.cc_myaccount_profile h3.panel-title.cc_title {
  margin-bottom: 10px;
}


/*----- Carousel -----*/

.carousel-control.right,
.carousel-control.left {
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0, rgba(0, 0, 0, .5) 100%);
  background-image: none;
  width:            10%;
  opacity:          .8;
  filter:           progid:DXImageTransform.Microsoft.gradient(startColorstr='$real_black00', endColorstr='#80000000', GradientType=1);
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width:       30px;
  height:      30px;
  margin-top:  -10px;
  font-size:   29px;
  font-weight: 100;
  color:       $white;
}

#seller-carousel .carousel-inner {
  position: relative;
  width:    90%;
  overflow: hidden;
  margin:   0 auto;
}

#seller-carousel .carousel-control {
  position:         absolute;
  bottom:           0;
  top:              0;
  left:             0;
  width:            25px;
  font-size:        99px;
  font-weight:      normal;
  padding-top:      30px;
  color:            #1B1B1B;
  text-align:       center;
  text-shadow:      0 1px 2px rgba(0, 0, 0, .6);
  background-color: rgba(0, 0, 0, 0);
  filter:           alpha(opacity=50);
  opacity:          .5;
}

#seller-carousel a.right.carousel-control.cc_right_carousel-control {
  left: inherit;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  max-width: none;
  height:    auto;
  width:     100%;
}


/*----- General Assets -----*/

.thumbnail {
  display:          block;
  padding:          0px;
  margin-bottom:    20px;
  background-color: $white;
  border:           none;
  border-radius:    0px;
}

.label-default {
  background-color: $brand_red;
}

.panel {
  margin-bottom:    20px;
  background-color: $white;
  border:           none;
  border-radius:    0;
  box-shadow:       none;
  padding:          0 0 10px 0;
}

.seller-locator-search {
  margin-bottom: 20px
}

.panel.panel-default.cc_panel.cc_wishlist_panel {
  background-color: #F3F3F3;
  padding:          15px 25px;
  margin-bottom:    5px;
}

.panel.panel-default.cc_panel.cc_category_tree {
  border-bottom: 2px solid #DDDDDD;
}

.cc_category_tree span.pull-right.cc_icon_area {
  position: absolute;
  right:    0;
  top:      10px;
}

.panel-heading {
  padding:       5px 0 5px;
  border-bottom: none;
}

.panel-title,
h3.cc_section_title {
  margin-top:     0;
  margin-bottom:  0;
  font-weight:    700;
  letter-spacing: 0;
  padding:        10px 0;
}

.well {
  min-height:       20px;
  padding:          30px;
  margin-bottom:    20px;
  background-color: #F3F3F3;
  border:           none;
  border-radius:    0;
  box-shadow:       none;
}

.cc_right_col .panel-heading {
  padding-bottom: 0
}

.cc_product_catalog_panel h3.cc_cat_name {
  color:      $real_black;
  margin-top: 0;
}

.panel-body {
  padding: 10px 0 0;
}

.panel-footer {
  padding:          0;
  background-color: transparent;
  border-top:       none;
}

.panel-default > .panel-heading {
  color:            $brand_red;
  background-color: transparent;
  border-color:     #DDDDDD;
  text-transform:   uppercase;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0px;
  padding:       0 0 2px;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #DDDDDD;
  padding-bottom:   10px;
}

.img-rounded {
  border-radius: 0px;
}

.promotion-box-LeftNav .panel {
  border:         none;
  padding-bottom: 0;
}

hr {
  border-top: 1px solid transparent;
  margin:     0;
}

.cc_content_body {
  padding-top:    30px;
  padding-bottom: 100px;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #1A1A1A;
}

.nav-tabs > li > a {
  border-radius: 0;
  color:         #676767;
}

.tab-content.cc_tab-content {
  padding: 30px 0;
}

p.secContentDescription b {
  margin:  20px 0 15px;
  display: block;
}

p.secContentDescription ul {
  margin:  10px 0 15px 25px;
  padding: 0;
}

p.secContentDescription li {
  margin: 10px 0 0 0;
}

.widgetSection .panel-heading.cc_heading {
  border-bottom: 2px solid #676767;
  margin-bottom: 20px;
}

.img-thumbnail {
  border-radius: 0;
}

.modal-content {
  border:        none;
  border-radius: 0;
  padding:       20px;
  text-align:    left;
}

img.media-object.cc_media-object.img-responsive {
  margin: 0 auto 30px;
}


/*----- Buttons -----*/

.btn-success {
  color: #4B9B78;
}

.btn-info {
  color: #4B9B78;
}

.btn-warning {
  color: #DDA036;
}

.btn-danger {
  color: $brand_red;
}

.form-group .btn {
  margin-top: 20px;
}

.input-group-btn .btn {
  padding:       8px 12px 9px;
  margin-bottom: 0;
  margin-top:    0;
}

.cc_mini_quick_order button.btn.btn-default.btn-sm.addMore.cc_addmore {
  float: right;
}

button.btn.btn-default.btn-xs.add_to_compare_desktop_button.cc_addto_compare {
  background-color: white;
  color:            $brand_red;
}

button.btn.btn-primary.btn-xs.add_to_compare_desktop_button {
  background-color: transparent;
  color:            $brand_red;
  padding:          5px 0;
}

button.btn.btn-primary.btn-sm.addItem.cc_add_item.pull-right {
  width: 143px;
}


/*----- Forms -----*/

.quantity .form-control {
  text-align:       right;
  background-color: transparent;
}

.form-group {
  margin-bottom: 15px;
  text-align:    left;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:   #979797;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #979797;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #979797;
}


/*----- Minicart -----*/

.minicart {
  padding:          0;
  margin-bottom:    30px;
  background-color: #F3F3F3;
}

.panel.panel-default.cc_mini_cart {
  border:           none;
  background-color: transparent;
  margin:           0;
  padding:          10px 20px 20px;
}

.minicart .cc_product,
.minicart .cc_qty,
.minicart .cc_total_qty,
.minicart .cc_total_price,
.form-group label {
  /*    padding-bottom: 5px;*/
  font-weight:   600;
  color:         $real_black;
  margin-top:    5px;
  margin-bottom: 0;
}

.mini_cart span.cc_subtotal {
  float: right;
}

.minicart .cc_total_price,
.minicart .cc_qty {
  text-align: right
}

.summary.cc_summary {
  padding-top: 15px;
  border-top:  1px solid #CCCCCC;
  margin-top:  15px;
}

.minicart button.btn.btn-default.loggedInAction.cc_logged_in_action {
  float: right;
}

.form-horizontal .form-group {
  margin-bottom: 5px;
}


/*----- Alerts -----*/

.alert-info {
  color:            $grey_level_1;
  font-style:       italic;
  background-color: #D9EDF7;
  border-color:     #BCE8F1;
  border-radius:    0;
}

.alert-danger {
  color:            $brand_red;
  background-color: #F2DEDE;
  border:           1px solid $brand_red;
}

/*----- Homepage custom static content -----*/

.custom-static-content {
  .editableImage {
    text-align: center;

    img {
      width:     auto;
      height:    auto;
      max-width: 100%;
    }
  }

  figure img {
    margin-bottom: 35px;
  }

  figcaption {
    margin-top: 20px;
  }
}

/*----- Wishlist -----*/

.quickwishdesk.cc_quickwishdesk {
  margin: 20px 0 0;
}

.cc_addto_cart {
  margin-top: 25px;
}


/*----- Product Details -----*/

.secRelatedAccessories .prodDetRel {
  max-height: 200px;
}

.sku.cc_sku {
  margin: 10px 0 5px;
}

.inventory.cc_inventory {
  margin:    5px 0;
  font-size: 9px;
}

.prodDetailContainer .form-horizontal .control-label {
  padding-top:   7px;
  margin-bottom: 0;
  text-align:    left;
  font-weight:   normal;
}

label.col-sm-7.control-label.cc_qty {
  width:         20px;
  padding-left:  0;
  padding-right: 0;
}

input#qty {
  width: 63px;
}

.col-md-2 i.fa {
  text-align:  right;
  display:     inline-block;
  margin-left: 8px;
  color:       $brand_red;
}

.cc_main_prod_image {
  margin-bottom: 20px;
}

.products_1_row_medium_item.cc_product_item {
  margin-bottom: 50px;
}

.quick_wishlist.cc_quick_wishlist .row {
  margin: 0px;
}

.cc_product_catalog_panel .col-xs-2 {
  width:      100%;
  /*margin-bottom: 40px;*/
  text-align: center;
}

.cc_product_catalog_panel .row {
  width:  47.5%;
  float:  left;
  margin: 0px 0 10px;
}

.cc_product_catalog_panel .row:nth-child(even) {
  float: right;
}

.cc_product_catalog_panel img.categoryList {
  /*width: 100%;*/
}

.cc_product_catalog_panel .panel-heading.cc_heading {
  border-top:    1px solid #CCCCCC;
  border-bottom: 2px solid #CCCCCC;
}

a.productName.cc_product_name {
  text-transform: uppercase;
  color:          $real_black;
  font-weight:    900;
}

.container-fluid.cc_demo_splash {
  overflow: hidden;
  position: relative;
  width:    100%
}

.cc_compare_text_row .cc_compare_text_col {
  vertical-align: top;
}


/*----- Filters -----*/

.cc_filter_breadcrumbs .cc_filter_breadcrumb.label {
  font-size: 100% !important;
  padding:   .3em .6em !important;
  margin:    2px 0;
}

.cc_filter_breadcrumb_panel .cc_remove_filters {
  padding:          0;
  background-color: transparent;
  color:            black;
  text-decoration:  underline;
}

.noUi-target {
  border-radius: 0px;
  border:        1px solid #D3D3D3;
  box-shadow:    inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBBBBB;
  width:         70%;
  background:    darkgrey;
}

.spec .upperDisp {
  margin-right: 23%;
  font-size:    10px;
  padding:      10px 0;
  display:      block;
}

.spec .lowerDisp {
  font-size: 10px;
  padding:   8px 0;
  display:   block;
}

.spec.cc_spec {
  position: relative;
}

button.slideFilter.btn.btn-default.btn-xs.cc_slide_filter {
  display:  inline-block;
  position: absolute;
  right:    0;
  top:      13px;
  padding:  3px 10px;
}

.panel-collapse {
  position: relative;
}

.panel.panel-default.cc_panel.cc_panel_filter_inner {
  margin-top: 30px;
}

.fa-lg {
  color: #989898;
}

i.fa.fa-lg.fa-th-list.listview {
  float: right;
}

i.fa.fa-lg.fa-th.gridview {
  float:       right;
  margin-left: 10px;
}

#categories-right-nav a {
  padding: 0px;
  display: block;
}


/*----- Cart -----*/


/*.cartContainer,
.prodDetailContainer {
    padding-right: 70px;
}*/

.cc_cart_item_list .cc_price_block .price.cc_price {
  font-size: 16px;
  color:     #1B1B1B;
}

.cc_cart_item_list a.prodLink.cc_prod_link {
  font-weight:    700;
  text-transform: uppercase;
  color:          $real_black;
  font-size:      13px;
}

.cart_item {
  padding:       30px 0;
  margin:        0 0 30px;
  border-bottom: 2px solid #CCCCCC;
}

.cart-links {
  font-size: 10px;
}

.cc_cart_item_container .cc_cart_item_list .cc_price_block {
  text-align: right;
}


/*----- MY ACCOUNT -----*/

.cc_myaccount_nav_list a {
  text-transform: uppercase;
  color:          $real_black;
  font-weight:    700;
  font-size:      11px
}

.cc_myaccount_nav_list li {
  padding: 12px 0 10px;
}

ul#side_nav {
  margin: 20px 0;
}

button.slideFilter.btn.btn-default.btn-xs.cc_slide_filter {
  display:  inline-block;
  position: absolute;
  right:    0;
  top:      13px;
  padding:  3px 10px;
}

.panel.cc_myaccount_information,
.panel.cc_myaccount_user_information,
.panel.cc_myaccount_contact_information {
  border-bottom:  2px solid #CCCCCC;
  padding-bottom: 40px;
  margin-bottom:  30px;
}

.cc_myaccount_information .panel-heading.cc_heading,
.cc_myaccount_user_information .panel-heading.cc_heading {
  margin:  0;
  padding: 0;
}

.cc_main_content_col form {
  margin: 30px 0;
}

/*----- Table Styles -----*/

.table-responsive {
  background-color: $white;
  padding:          30px;
  margin:           30px 0;
}


/*thead,
.cc_table_header_row {
    background-color: #8ca1b1;
    color: #fff;
}*/

tr.cc_table_row,
tr {
  border-bottom: 2px solid #CCCCCC;
}

.table {
  margin: 0;
}

thead.compareRow.compareSKURow.cc_compare_sku_row {
  background-color: transparent;
}

th.compareCol.compareSKUCol.cc_compare_sku_col {
  padding: 8px;
}

tr.compareRow.compareImageRow.cc_compare_image_row,
tr.compareTextRow.compareNameRow.cc_compare_name_row,
tr.compareTextRow.compareDescRow.cc_compare_text_row,
tr.compareRow.compareAddtInfoRow.cc_compare_add_info_row,
tr.compareRow.compareUomRow.cc_compare_uom_row,
tr.compareRow.comparePriceRow.cc_compare_price_row,
tr.compareRow.compareQtyAvailRow.cc_compare_qty_avail_row,
tr.compareRow.compareAddToRow.cc_compare_addto_row,
thead.compareRow.compareSKURow.cc_compare_sku_row th {
  border: none;
}

.compareBasicInfo .form-group .btn {
  margin-top: 15px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td,
td {
  padding:        10px;
  line-height:    1.42857143;
  vertical-align: middle;
  border-top:     none;
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 0;
}

.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius:  0;
  border-top-right-radius: 0;
}

.table-hover > tbody > tr:hover {
  background-color: $lightgrey;
}

table .cc_table_header i.fa {
  font-size: 7px;
  color:     $white;
}

#seller-carousel .col-md-3 {
  width:      25%;
  text-align: center;
}


/*----- Specs ----- */

.nvRow.cc_nv_row.row .col-xs-6 {
  padding-left: 0;
}

p.groupName.cc_group_name {
  padding: 10px;
}

.nvRow.cc_nv_row.row {
  padding: 10px 0;
  margin:  0;
}

.col-md-6.cc_main_content_col {
  padding: 0 10px;

  @media only screen and (max-width: $screen-sm-max) {
    padding: 0;
  }

}

div#seller-carousel .carousel-indicators {
  display: none;
}

.secRelatedCrossSell.cc_secRelatedCrossSell {
  text-align: center;
}

p.cc_prod_name a,
p.cc_seller_name,
p.name.cc_name {
  text-transform: uppercase;
  color:          $real_black;
  font-weight:    700;
  font-size:      13px;
}

.quick_wishlist.cc_quick_wishlist .cc_wish_row {
  margin:         10px 0;
  border-bottom:  2px solid #CCCCCC;
  padding-bottom: 20px;
}

.text-center.cc_current_page {
  padding-top: 5px;
}

.cc_pricing.control p.price {
  color:       $real_black;
  padding-top: 10px;
  font-size:   16px;
}

label.cc_items_per_page {
  font-weight: normal;
}

.value.cc_value {
  margin-bottom: 5px;
}

.dynamicKitSection.cc_dynamic_kit_section .panel {
  margin-bottom:  0;
  padding-bottom: 0;
}

.cc_action_totals.pull-right {
  padding-top: 10px;
}

.cc_qty_control_row input.btn.btn-default.btn-sm {
  font-size: 11px;
  padding:   9px 8px 7px;
  width:     25px;
  height:    30px;
}

.grand_total p {
  color:     $real_black;
  font-size: 19px;
  margin:    30px 0;
}

p.cc_paginator_legend {
  padding-top: 5px;
}

.breadcrumb > li + li:before {
  padding:   0 10px 0 15px;
  color:     #CCCCCC;
  content:   "/\00a0";
  font-size: 14px;
}

.img-thumbnail {
  padding:       0;
  border:        none;
  border-radius: 0;
}

.cc_wish_form .col-md-2.cc_wish_img {
  padding: 0;
}

#at4-share,
#at4-soc {
  top:    50px !important;
  bottom: auto;
}

.cc_rank_display {
  margin:  5px 0;
  display: block;
}

.products_1_row_medium_item.cc_product_item .row.cc_qty_control_row {
  margin-bottom: 5px;
}

.cc_tabs_container img {
  width: auto;
}

.cc_main_container .cc_quick_wishlist_item img {
  width:     auto;
  margin:    0 auto;
  max-width: 100%;
}

.cc_quick_wishlist {
  text-align: center;
}

img.mainProdImage.prodDetail.img-responsive {
  width: 100%;
}

.wishFinder button.btn.btn-default.btn-sm.addItem.cc_add_item,
.wishButtons button#dropWish {
  padding: 4px 7px 2px;
  color:   $white;
}

.col-md-4.col-md-offset-3.wishFinder {
  margin-left: 15px;
}

.fa-lg {
  color:          $white;
  font-size:      1em;
  line-height:    1em;
  vertical-align: 0;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus,
.btn:active,
.btn.active {
  outline:        none;
  outline-offset: -2px;
  box-shadow:     none;
}

.panel.panel-default.cc_panel.cc_shopping_cart_discount_panel {
  background-color: #F3F3F3;
  padding:          30px;
}

form#couponAddForm {
  margin: 0;
}

.cc_myaccount_content.panel-body.cc_body {
  padding: 0;
}

button.btn.btn-default.btn-sm.searchFilter.cc_seaerch_filter_btn,
button.btn.btn-default.btn-sm.addItem.cc_add_item,
button.btn.btn-default.btn-sm.addToCart.cc_addtocart,
button.btn.btn-default.btn-sm.addMore.cc_addmore,
button.btn.btn-default.btn-sm.loggedInAction.cc_logged_in_action,
button.btn.btn-default.btn-sm.updateCartButton.cc_update_cart_button,
.wishFinder .cc_action button.btn.btn-default.btn-sm.addItem.cc_add_item,
input.btn.btn-default.btn-sm.register.cc_register,
#collapseSellerLocatorSearch input.btn.btn-default.btn-sm.search-button.cc_search-button,
input.cc_login_button.btn-sm,
button.btn.btn-default.btn-sm.addToCart.cc_add_to_cart,
button.addAddress.btn.btn-default.btn-sm.cc_add_address_btn,
input.btn.btn-default.btn-sm.cc_cancel_addr.cancelAddr,
input.btn.btn-default.btn-sm.saveAddr.cc_save_addr,
input.btn.btn-default.btn-sm.gotoSectionContactInfoEdit.cc_edit_profile,
button.openModalAddr.btn.btn-default.btn-sm.cc_open_modal_addr,
button.deleteAddress.btn.btn-default.btn-sm.cc_delete_addr,
input.btn.btn-default.btn-sm.createTemplate.cc_create_template,
button.btn.btn-default.btn-sm.makePayment.cc_make_payment,
input.btn.btn-default.btn-sm.processReview.proceed.cc_process_review,
input.btn.btn-default.btn-sm.processBack.cc_process_back,
button.btn.btn-default.btn-sm.addItem.cc_add_item.pull-right,
input#submit,
input.btn.btn-default.btn-sm.processLLIShippingInfo.proceed.cc_proceed,
input.btn.btn-default.btn-sm.processReview.proceed.cc_proceed {
  min-width: 100px;
}

button.btn.btn-default.btn-sm.searchFilter.cc_seaerch_filter_btn {
  min-width: 0;
}

button.btn.btn-default.btn-sm.addToCart.cc_add_to_cart {
  margin-top: 0
}

.col-xs-12.cc_footer_logo_col img {
  max-width: 150px;
}

.splash-promo-Desktop img {
  width: 100%;
}

.col-md-5.cc_wish_prod_details {
  text-align: left;
}

.collapse h5.panel-title,
.collapsing h5.panel-title {
  font-weight: 300;
}

.cc_product_catalog_panel .cc_body .col-xs-10 p {
  text-align: center;
}

.cc_product_catalog_panel .cc_body .col-xs-10 {
  width: 100%;
}

.caret {
  margin-left: 10px;
}

.btn .caret {
  margin-left: 10px;
}

.cc_right_col .quick-order {
  margin-bottom: 20px;
}

header {
  /*    position: fixed;*/
  width:   100%;
  left:    0;
  top:     0;
  z-index: 1050;
}

.mini_cart span.cc_cart_item_count,
.mini_cart span.cc_subtotal {
  font-weight: 700;
}

.mini_cart .cc_quantity .input.form-control {
  background-color: transparent;
}

.cc_mini_quick_order p.cc_caption {
  line-height: 1.2;
}

ol.breadcrumb.cc_breadcrumb li:last-child a {
  font-weight: 700;
}

.modal-title,
.modal-header h3 {
  margin:         0;
  line-height:    1.42857143;
  font-size:      20px;
  color:          $real_black;
  font-weight:    700;
  letter-spacing: 0;
  padding:        0;
}

.cc_modal.cc_cart_attribute_items_modal .modal-dialog {
  width: 100%;
}

a#additionalInfoLink {
  font-size: 10px;
}

.filter_breadcrumb_container_main.cc_filter_breadcrumbs {
  margin: 20px 0;
}

.filter_breadcrumb_container_main.cc_filter_breadcrumbs .label {
  font-weight:  300;
  margin-right: 10px;
  font-size:    10px;
}

span.label.label-default.pull-right.cc_label.cc_check_filter_label {
  font-size: 10px;
  float:     right !important;
}

.filterContainer .collapse h5.panel-title {
  font-weight: 700;
}

.filterContainer .panel-default > .panel-heading {
  border-bottom: 2px solid #CCCCCC;
}

.filterContainer .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border:         none;
  padding-bottom: 10px;
}

a.productName.detailLin.cc_detail_linkk {
  text-transform: uppercase;
  font-size:      12px;
  color:          $real_black;
  font-weight:    700;
  margin-bottom:  0px;
}

a.remove.cc_remove {
  color: #CCCCCC;
}

.noUi-horizontal .noUi-handle {
  border-radius:    50%;
  box-shadow:       none;
  border:           1px solid #CCCCCC;
  background-color: #4C788F;
  width:            15px;
  height:           15px;
}

.noUi-base {
  margin-top: 10px;
}

.noUi-target {
  border-radius: 0px;
  border:        none;
  box-shadow:    none;
  width:         70%;
  background:    #CCCCCC;
  height:        4px;
  margin-top:    4px;
}

.noUi-handle:before,
.noUi-handle:after {
  display: none;
}

.noUi-origin {
  border-radius: 0;
  top:           -3px;
}

.listPaginationSec .col-xs-4 {
  padding: 0;
}

.alert {
  padding:       15px;
  margin-bottom: 20px;
  border-radius: 0px;
  margin-top:    10px;
}

button.btn.btn-default.btn-sm.addToCart.cc_add_to_cart {
  margin-top: 0
}

.cc_main_container .cc_dynamic_kit_section .cc_quick_wishlist_item img {
  width:      auto;
  margin:     0 auto;
  max-width:  100%;
  max-height: 240px;
}

.cc_dynamic_kit_section .cc_name {
  text-align: left;
  height:     34px;
  margin-top: 10px;
}

.cc_dynamic_kit_section .form-control[disabled],
.cc_dynamic_kit_section .form-control[readonly],
.cc_dynamic_kit_section fieldset[disabled] .form-control,
.cc_dynamic_kit_section .form-control {
  float:     right;
  max-width: 50px;
}

input.form-control.input-sm.cc_qty_input {
  max-width: 50px
}

.cc_dynamic_kit_section .cc_pricing.control p.price {
  color:       $real_black;
  padding-top: 0px;
  font-size:   16px;
}

.cc_cart_sort_item .label {
  font-weight: 300;
}

.cc_dynamic_kit_section .cc_quick_wishlist {
  border-bottom:  2px solid #CCCCCC;
  margin-bottom:  20px;
  padding-bottom: 10px
}

.dynamicKitSelSection .cc_widget_column {
  padding-bottom: 10px;
  margin-top:     20px;
}

.dynamicKitSelSection .col-xs-2 {
  text-align: right;
}

.cc_widget_column span {
  font-weight: 700;
  color:       $real_black;
}

img.detailLink.prodDetRel.img-responsive {
  max-width: 200px;
  margin:    0 auto;
}

button.close {
  font-size: 25px;
}

.includedItemsSection .cc_quick_wishlist {
  border-bottom:  2px solid #CCCCCC;
  padding-bottom: 20px;
  margin-bottom:  20px;
}

.includedItemsSection h3.panel-title.cc_title {
  border-bottom: 2px solid #666666;
  margin-bottom: 30px;
}

.acctmainSection h3.panel-title.cc_title,
.cc_myaccount_profile h3.cc_title {
  font-size:   20px;
  font-weight: 700
}

.acctmainSection .cc_myaccount_profile h3.panel-title.cc_title {
  font-size: 13px
}

input.form-control.cc_address2 {
  margin: 5px 0;
}

.col-md-6.myAccBillingAddr.cc_billing_address,
.col-md-6.myAccShippingAddr.cc_shipping_address,
.col-md-6.myAccMailingAddr.cc_mailing_address,
.col-md-6.myAccOtherAddr.cc_other_address {
  background-color: #EFEFEF;
  padding:          25px 20px;
  width:            47.5%;
  margin-left:      15px;
  margin-top:       20px;
  float:            left;
}

.col-md-6.myAccShippingAddr.cc_shipping_address {
  float: right
}

.acctmainSection .cc_myaccount_general .cc_shipping_address {
  float: left;
}

span.cc_profile_billing_label,
span.cc_profile_shipping_label,
span.cc_profile_other_label,
span.cc_profile_mailing_label {
  background-color: #8CA1B1;
  width:            100%;
  display:          block;
  padding:          10px;
  color:            $white;
}

span.cc_profile_billing_value,
span.cc_profile_shipping_value,
span.cc_profile_mailing_value,
span.cc_profile_other_valuel {
  padding: 10px 0;
  display: block;
}

p.cc_addr_name {
  font-size:   13px;
  font-weight: 700;
}

p.cc_addr_company {
  font-size:      13px;
  font-weight:    700;
  text-transform: uppercase;
}

td.cc_address_control {
  text-align: right;
}

.table-hover > tbody > tr:hover {
  background-color: #DDDDDD;
}

.status.cc_address_type_value {
  font-weight: 700;
  color:       $real_black;
}

.form-group-sm .form-control {
  border-radius: 0;
}

.panel.panel-default.cc_panel.seller-locator.cc_seller_list_panel {
  border-bottom: 2px solid #CCCCCC;
}

.nav-justified li.cc_checkoutStep a {
  background-color: #EEEEEE;
  display:          block;
  width:            100%;
  border-radius:    0;
  margin:           0;
  height:           50px;
  padding:          0;
  color:            $white
}

.nav-justified li.cc_checkoutStep.active a {
  background-color: #4C788E;
}

.nav-justified li.cc_checkoutStep.active a h4 {
  color: $white;
}

.nav-justified li.cc_checkoutStep a h4 {
  line-height: 51px;
  font-size:   13px;
  margin:      0;
  padding:     0;
}

ul.nav.nav-pills.nav-justified.thumbnail {
  margin-top: 0;
  position:   relative;
  top:        20px;
}

.cc_compare_tray_controls .col-xs-3.col-xs-offset-6 {
  text-align: right;
}

.cc_compareTrayProdSku span.cc_prodSku {
  display: none;
}

.prodListSec .cc_heading .col-md-2 {
  float:      right;
  text-align: right;
}

.row.collapse.compareCollapse:first-child {
  border-bottom:  2px solid #CCCCCC;
  padding-bottom: 5px;
}

form#buyerInfo .col-sm-6 {
  padding-left: 0;
}

form#buyerInfo .col-sm-6 label {
  margin-top: 10px;
}

.cc_compare_tray_name {
  font-size:      11px;
  text-transform: uppercase;
  font-weight:    700;
  color:          $real_black;
  text-align:     center;
}

.billingAddressPanel.col-xs-4 {
  padding: 10px;
}

div#addressCarousel .item .subItem {
  width: 100%;
}

div#addressCarousel {
  /*    border: 2px #3b677e;
  border-style: outset;
  padding: 2px;
  margin: 0px;
  background: #fff;*/
}

.panel.panel-default.cc_panel.cc_shipping_group_panel {
  background: #EEEEEE;
  padding:    10px;
}

.panel-body.cc_body.cart_summary_body.table-responsive {
  background-color: white;
  padding:          0;
  margin:           0;
}

.summaryWidget {
  border:  1px #CCCCCC solid;
  padding: 10px;
}

.cc_lliorder_details .panel.panel-default.cc_panel {
  background: #EEEEEE;
  padding:    15px;
}

.cc_lliorder_details .addressEntry.col-sm-6.cc_address_entry {
  padding: 0 0 0 15px;
}

.invoiceHeaderContainer.row.cc_invoice_header_container {
  background:    #EEEEEE;
  padding:       15px;
  margin-bottom: 15px;
}

.row.cc_subscription_order_row {
  border:        1px #EEEEEE solid;
  padding:       15px;
  margin-bottom: 5px;
}

.row.cc_subscription_order_history {
  background: #EEEEEE;
  padding:    15px;
}

.cc_myaccount_mywishlists_container .row.cc_wishlist_item {
  margin-top:  10px;
  padding-top: 10px;
  border-top:  1px #DDDDDD solid;
}

.cc_myaccount_mywishlists_container .cart_item {
  padding: 0px;
  margin:  0px;
  border:  0px;
}

.addressEntry.col-xs-12 {
  padding-left: 0;
}

.prodListSec .cc_product_item .cc_qty_control_row input#qty {
  margin-right: 0;
  float:        right;
}

.col-md-4.products_1_row_medium_item.cc_product_item {
  border:     1px #EEEEEE solid;
  min-height: 395px;
}

.cc_product_item .col-md-12:first-child a.productName.cc_product_name {
  /*height: 165px;
  overflow: hidden;*/
  display: block;
}

.cc_product_item .col-md-12 a.productName.cc_product_name {
  min-height: 36px;
  display:    block;
  margin-top: 15px;
}

.compareTrayProdImageSec.cc_compare_tray_img img {
  max-width:  75px;
  text-align: center;
  margin:     0 auto 10px;
}

.cc_compareTrayProdSku button.close {
  font-size: 24px;
  position:  absolute;
  color:     $real_black;
  top:       0;
  right:     0;
  margin:    0 10px 0;
}

.grid_buttons button.btn.btn-default.btn-sm.addItem.cc_add_item.pull-right {
  padding:   4px 7px 2px;
  min-width: 58px;
}

form#searchInvoiceForm .form-group {
  margin-right: 10px;
}

.cc_widget_column span.qty.cc_qty {
  float: left;
}

.cc_lli_move_items_panel {
  max-height: none;
  overflow-y: auto;
}

.storedPaymentContainer.po.cc_stored_payment_container {
  margin: 20px 0;
}

.cc_order_summary p.cc_subtotal {
  margin: 20px 0;
}

.cc_order_summary span.pull-left.cc_subtotal_label,
.cc_order_summary span.pull-left.cc_grand_total_label {
  float: none !important;
}

.cc_order_summary span.pull-left.cc_grand_total_label,
.cc_order_summary span.pull-right.cc_grand_total_value {
  float:     none !important;
  font-size: 19px;
}

.cc_order_summary span.pull-right.cc_grand_total_value {
  margin-left: 9px;
}

span.pull-right.cc_subtotal_value {
  float:       none !important;
  margin-left: 10px;
}

.summaryRow {
  font-size: 17px;
}

.addressEntry.col-sm-6.cc_address_entry {
  padding: 0 0 20px 0;
}

.cc_subscription_image {
  margin-bottom: 20px;
}

span.cc_subscription_product_label {
  font-size:      17px;
  text-transform: uppercase;
  color:          $real_black;
}

span.cc_subscription_product_label a.prodLink {
  color: $real_black;
}

.invoiceContainer.cc_invoice_action_container {
  text-align: left;
  float:      left;
  margin:     10px 0 20px;
}

.cc_secRelatedCrossSell p.cc_prod_name {
  margin-top: 20px;
}

.cc_results_list .cc_add_to .cc_add_to_btn {
  width: 100%;
  max-width: 240px;
  // @media all and (min-width: $screen-sm-min) {
  //   width: calc(100% - 30px);
  // }
}

/******************************************************
* TOOLTIP STYLES
*******************************************************/

.tooltip-inner {
  max-width:        200px;
  padding:          3px 8px;
  color:            $real_black;
  text-align:       center;
  background-color: #E4E4E4;
  border-radius:    0;
}

.tooltip.in {
  opacity: 1;
  filter:  alpha(opacity=100);
}

.tooltip-arrow {
  position:     absolute;
  width:        0;
  height:       0;
  border-color: transparent;
  border-style: solid
}

.tooltip.top .tooltip-arrow {
  bottom:           0;
  left:             50%;
  margin-left:      -5px;
  border-width:     5px 5px 0;
  border-top-color: #E4E4E4
}

.tooltip.top-left .tooltip-arrow {
  right:            5px;
  bottom:           0;
  margin-bottom:    -5px;
  border-width:     5px 5px 0;
  border-top-color: #E4E4E4
}

.tooltip.top-right .tooltip-arrow {
  bottom:           0;
  left:             5px;
  margin-bottom:    -5px;
  border-width:     5px 5px 0;
  border-top-color: #E4E4E4
}

.tooltip.right .tooltip-arrow {
  top:                50%;
  left:               0;
  margin-top:         -5px;
  border-width:       5px 5px 5px 0;
  border-right-color: #E4E4E4
}

.tooltip.left .tooltip-arrow {
  top:               50%;
  right:             0;
  margin-top:        -5px;
  border-width:      5px 0 5px 5px;
  border-left-color: #E4E4E4
}

.tooltip.bottom .tooltip-arrow {
  top:                 0;
  left:                50%;
  margin-left:         -5px;
  border-width:        0 5px 5px;
  border-bottom-color: #E4E4E4
}

.tooltip.bottom-left .tooltip-arrow {
  top:                 0;
  right:               5px;
  margin-top:          -5px;
  border-width:        0 5px 5px;
  border-bottom-color: #E4E4E4;
}

.tooltip.bottom-right .tooltip-arrow {
  top:                 0;
  left:                5px;
  margin-top:          -5px;
  border-width:        0 5px 5px;
  border-bottom-color: #E4E4E4
}


/******************************************************
* TIERED PRICING STYLES
*******************************************************/

.cc_prt_outer_row,
.cc_prt_button_outer {
  background-color: #EEEEEE;
}

.cc_prt_outer_row_dk {
  background-color: transparent;
}

.cc_prt_outer_row_dk .cc_prt_div_outer {
  background-color: #EEEEEE;
}

.cc_prt_button_outer_dk {
  /* background-color: #FFF; */
}

.cc_prt_div_outer.cc_prt_div_outer {
  margin-top:  10px;
  padding-top: 10px;
}

.cc_prt_tool_tip_div_dk {
  margin-bottom: 10px;
}

.cc_prt_button_outer {
  padding-bottom: 10px;
}

.cc_prt_heading_label_div {
  margin-bottom: 10px;
}

.cc_prt_tier_box_selected.cc_prt_tier_box_text,
.cc_prt_tier_box_unselected.cc_prt_tier_box_text,
.cc_prt_tier_box_primary.cc_prt_tier_box_text,
.cc_prt_tier_box_overflow.cc_prt_tier_box_text {
  text-align: center;
  padding:    5px;
  margin:     5px 0;
}

.cc_prt_tier_box_selected.cc_prt_tier_box_text {
  background-color: #D7D7D7;
  color:            #333333;
  border:           1px solid #B3B3B3;
}

.cc_prt_tier_box_unselected.cc_prt_tier_box_text {
  background-color: $white;
  color:            #666666;
  border:           1px solid #DCDCDC;
}

.cc_prt_tier_box_text.cc_prt_tier_box_text_range {
  font-weight: 500;
}

.cc_prt_tier_box_text.cc_prt_tier_box_text_price {
  font-weight: 700;
}

.cc_prt_button.cc_collapse_button {
  background-color: #666666;
  color:            $white;
}

.cc_prt_button.cc_collapse_button:focus {
  outline: none;
}

.cc_prt_cart_row,
.cc_prt_outer_row_cart,
.cc_prt_cart_ttrow {
  background-color: #EEEEEE;
  padding:          5px 20px;
}

.cc_prt_heading_label {
  margin-top: 10px;
}

.cc_prt_tool_tip_div_ct {
  text-align: center;
}

.cc_prt_tool_tip_text_ct {
  display:       block;
  margin-bottom: 10px;
}
