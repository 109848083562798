/* PLP Categories */
.cc_category_row {
  .show-subcat {
    display: block;
    margin: 0 auto;
    text-transform: capitalize;
    padding-top: 7px;
  }

  .cc_subcategories {
    line-height: 2;

    a {
      padding-right: 10px;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $bg_hover;
        text-decoration: underline;
      }
    }
  }
}

.toggle-subcategories {
  max-height: 32px;
  overflow: hidden;
}
